<template>
  <div class="modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Manage Terms</h4>
          <ZgButton class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')" type="button"></ZgButton>
        </div>
        <div class="modal-body">
          <div class="content-fluid">
            <div class="row my-2">
              <h5 class="modal-title mb-3">Terms for Fields</h5>
              <div class="col title">Term name</div>
              <div class="col title">Display name</div>
              <div class="col-1"></div>
            </div>
            <div class="row px-2 py-1 form-group" v-for="term in getCCorpTerms('field')" :key="term.uuid">
              <div class="col">
                {{term.term_name}}
              </div>
              <div class="col">{{term.display_name}}</div>
              <div class="col-1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="deleteTerm(term.uuid)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>
            <div class="row my-2">
              <h5 class="modal-title mb-3">Terms for Fees</h5>
              <div class="col title">Term name</div>
              <div class="col title">Display name</div>
              <div class="col-1"></div>
            </div>
            <div class="row px-2 py-1 form-group" v-for="term in getCCorpTerms('fee')" :key="term.uuid">
              <div class="col">
                {{term.term_name}}
              </div>
              <div class="col">{{term.display_name}}</div>
              <div class="col-1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="deleteTerm(term.uuid)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer modal-form">
          <div class="content-fluid">
            <div class="row mb-4 form-group">
              <div class="col-4">
                <select class="form-control form-control-sm" v-model="new_term.term_type">
                  <option value="">Select a term type</option>
                  <option value="field">Field</option>
                  <option value="fee">Fee</option>
                </select>
              </div>
              <div class="col"><input class="form-control form-control-sm" placeholder="Term name" type="text" v-model="new_term.term_name" /></div>
              <div class="col"><input class="form-control form-control-sm" placeholder="Display name" type="text" v-model="new_term.display_name" /></div>
              <div class="col-1">
                <ZgButton class="btn btn-success zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="addNewTerm()" type="button">
                  <template v-slot:title><i class="fa fa-plus"></i></template>
                </ZgButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import ZgButton from '../common/Generics/ZgButton.vue'

export default {
  name: 'ServicesCCorpManageTerms',
  data(){
    return {
      new_term: {
        term_name: '', 
        display_name: '', 
        term_type: ''
      }
    }
  },
  components: {
    ZgButton
  },
  methods: {
    ...mapActions({
      addCCorpTerm: 'products/addCCorpTerm',
      fetchCCorpTerms: 'products/fetchCCorpTerms',
      deleteCCorpTerm: 'products/deleteCCorpTerm'
    }),
    addNewTerm() {
      this.addCCorpTerm({new_term: this.new_term}).then(() => {
        this.new_term = {}
        this.fetchCCorpTerms()
      })
    },
    deleteTerm(term_uuid) {
      this.deleteCCorpTerm({term_uuid: term_uuid}).then(() => {
        this.fetchCCorpTerms()
      })
    },
    getCCorpTerms(term_type){
      return this.$store.state.products.ccorpTerms.filter((term) => term.term_type === term_type)
    }
  },
  mounted() {
    this.fetchCCorpTerms()
  }
};
</script>

<style lang="scss" scoped>
  .modal-form {
    justify-content: center;
  }
  .modal-body .title {
    font-weight: 700;
  }
</style>
