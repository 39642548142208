<template>
   <div class="modal">
     <div class="modal-dialog modal-dialog-centered modal-xl" >
       <div class="modal-content min-width-fit-content height-modal">
          <div class="modal-header">
            <h5 class="modal-title">Add Affiliate Pricing</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
          </div>
          <div class="modal-body overflow-y-auto">
            <div class="form-group col-md">
              <div class="form-row">
                <label v-bind:class="{'text-danger': !isValidAffiliate}">Affiliate</label>
              </div>
              <div class="form-row">
                <v-select class= "w-100" v-model="selectedAffiliate" :options="affiliates" label="name"/>
              </div>
            </div>
            <div class="form-group col-md">
              <div class="form-row">
                <label v-bind:class="{'text-danger': !arePricesSelected}">Service Pricing</label>
              </div>
              <div class="form-row">
                  <v-select
                    class= "w-100"
                    :value="selectedPrice"
                    :options="selectablePrices"
                    label="service"
                    @input="addPriceRow"
                  >
                    <template #option="{ service, amount, interval, price_group, defer_until_formation_complete }">
                      <h6 class="m0">{{ service }}</h6>
                      <em> ${{ amount }} {{ interval }} <span v-if="defer_until_formation_complete"> delayed</span><span v-if="price_group">({{ price_group }})</span></em>
                    </template>
                  </v-select>
              </div>
            </div>
            <div class="form-row border border-dark ms-auto">
              <div class="form-group col-md">
                <div class="form-row">
                  <div class="form-col md">
                    <b-table striped hover :items="selectedPrices" :fields="fields" class="min-height-350">
                      <template v-slot:cell()="{ value, item, field:{ key }}" >
                        <template v-if="!item.isEditing">{{ value }}</template>
                        <b-form-input v-else v-model="item[key]" />
                      </template>
                      <template v-slot:cell(actions)="{ item }">
                        <button class="btn-info btn-sm"  @click='onDelete(item)'>Delete</button>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" :disabled="!isFormValid" @click="saveAffiliatePrices()">Create</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'AffiliatePriceAddItem',
  computed: {
    ...mapState({
      affiliates: (state) => state.products.affiliates,
      servicePricingV2: (state) => state.products.servicePricingV2
    }),
    isValidAffiliate() {
      if (this.selectedAffiliate) {
        return true;
      }
      return false;
    },
    arePricesSelected() {
      if (this.selectedPrices.length > 0) {
        return true;
      }
      return false;
    },
    isFormValid() {
      if (this.isValidAffiliate && this.arePricesSelected) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      selectedAffiliate: null,
      selectedPrice: null,
      selectedPrices: [],
      selectablePrices: [],
      fields: [
        {
          key: 'service_pricing_uuid',
          label: 'uuid'
        },
        {
          key: 'service',
          label: 'Service',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'interval',
          label: 'Interval'
        },
        {
          key: 'coupon',
          label: 'Coupon'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      fetchAffiliatesV2: 'products/fetchAffiliatesV2',
      fetchServicePricingV2: 'products/fetchServicePricingV2'
    }),
    addPriceRow(selectedPrice) {
      this.selectedPrice = selectedPrice;
      this.selectedPrices.push(selectedPrice);
      const selectedPricingUuids = this.selectedPrices.map((selectedPrice) => selectedPrice.service_pricing_uuid);
      this.selectablePrices = this.servicePricingV2.filter((price) => selectedPricingUuids.indexOf(price.service_pricing_uuid) === -1);
      this.selectedPrice = null;
    },
    saveAffiliatePrices() {
      if (this.isFormValid) {
        for (const price of this.selectedPrices) {
          const newPrice = {
            affiliate: this.selectedAffiliate.uuid,
            service_pricing: price.service_pricing_uuid
          };
          this.$store.dispatch('products/createAffiliatePricing', newPrice)
            .then(() => {
              this.$emit('close');
            });
        }
      }
    },
    onDelete(item) {
      this.selectedPrices = this.selectedPrices.filter((selectedPrice) => selectedPrice.service_pricing_uuid !== item.service_pricing_uuid);
      const selectedPricingUuids = this.selectedPrices.map((selectedPrice) => selectedPrice.service_pricing_uuid);
      this.selectablePrices = this.servicePricingV2.filter((price) => selectedPricingUuids.indexOf(price.service_pricing_uuid) === -1);
    }
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: false});
    this.fetchServicePricingV2()
      .then(() => {
        this.selectablePrices = this.servicePricingV2;
      });
  }
};
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
  }
  .height-modal {
    max-height: 80vh;
  }
  .min-height-350 {
    min-height: 350px;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
</style>
