<template>
   <div class="modal">
     <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Create new Coupon</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="clearFields(); $emit('close')"></button>
          </div>
          <div class="modal-body">
              <div class="form-row p-2">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validNoEmptytext(this.coupon.productNameIds)}">Product Ids</label>
                  <p class="text-muted" style="font-size: 11px">(Product name ids must match service_product_name_ids, must be separated by comma, Starter/Pro/Premium plans - if not applicable to separate versions must be entered as 'starter_plan', 'pro_plan', 'premium_plan')</p>
                  <input v-model="coupon.productNameIds" class="form-control" placeholder="Product ids" name="Coupon" id="product-name-ids" required />
                </div>
              </div>
              <div class="form-row p-2">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validCouponId(this.coupon.externalCouponId)}">Coupon</label>
                  <p class="text-muted" style="font-size: 11px">(external_coupon_id cannot be more than {{maxLengthCouponId}} characters)</p>
                  <input v-model="coupon.externalCouponId" class="form-control" placeholder="Coupon Id" name="Coupon" id="external-coupon-id" required />
                </div>
              </div>
              <div class="form-row  p-2">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validAmountValue(this.coupon.amount)}" for="amount">Amount</label>
                  <input v-model="coupon.amount" class="form-control" placeholder="0" name="Amount" id="amount" required/>
                </div>
              </div>
              <div class="form-row  p-2">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validNoEmptytext(this.coupon.duration)}">Duration</label>
                  <select class="w-100" label="duration" v-model="coupon.duration" disabled>
                    <option value="once">Once</option>
                  </select>
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button class="btn btn-primary" :disabled="!isFormValid" @click="handleCreateCoupon" >Create</button>
          </div>
       </div>
    </div>
  </div>
</template>

<script>
import { resource } from 'vuex-pagination';
import { mapGetters } from 'vuex';

export default {
  name: 'CouponCreateModal',
  computed: {
    ...mapGetters('products'),
    isFormValid() {
      const { amount, duration, externalCouponId, productNameIds } = this.coupon;
      let isValid = this.validCouponId(externalCouponId) && externalCouponId.indexOf(' ') === -1;
      isValid = isValid && productNameIds && productNameIds.length > 0;
      isValid = isValid && amount > 0;
      isValid = isValid && duration && duration.length > 0 && duration.indexOf(' ') === -1;
      return isValid;
    }
  },
  data() {
    return {
      coupon: {
        externalCouponId: '',
        amount: 0,
        duration: 'once'
      },
      couponCreated: true,
      maxLengthCouponId: 40
    };
  },
  methods: {
    beforeCreate() {
      this.clearFields();
    },
    clearFields() {
      this.coupon.externalCouponId = '';
      this.coupon.amount = 0;
    },
    handleCreateCoupon() {
      const newCoupon = {
        external_coupon_id: this.coupon.externalCouponId,
        amount: this.coupon.amount,
        duration: this.coupon.duration,
        service_product_name_ids: this.coupon.productNameIds.split(',').map((id) => id.trim())
      };
      this.$store.dispatch('products/createCoupon', newCoupon)
        .then(() => {
          this.clearFields();
          resource('coupons').refresh();
          this.$emit('close');
        });
    },
    validAmountValue(value) {
      if (!value || value === '' || value === '0' || Number(value) <= 0) {
        return false;
      }
      return true;
    },
    validCouponId(value) {
      if (!value || value === '' || value.length > this.maxLengthCouponId) {
        return false;
      }
      return true;
    },
    validNoEmptytext(value) {
      if (!value || value === '' || value.indexOf(' ') !== -1) {
        return false;
      }
      return true;
    }
  }
};
</script>
