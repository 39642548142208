<template>
  <div>
    <vue-headful title="Amendments & Annual Reports Configuration" />
    <div class="configs">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h4>Amendments & Annual Reports Configuration</h4>
        </div>
        <div class="col-auto mb-2 d-flex">
          <router-link tag="button" class="btn btn-info" to="/products/amendment-report-field-config/add" v-if="isAllowedToEditAnnualReportConf">
            Add
          </router-link>
          <router-link tag="button" class="btn btn-info" to="/products/amendment-report-field-config/edit" v-if="isAllowedToEditAnnualReportConf">
            Edit
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col col-lg-2">
          <form>
            <label for="selectStates">Select State</label>
            <multiselect id="selectStates"
              v-model="localStates"
              :options="sortedStates"
              :close-on-select="true"
              :hide-selected="true"
              :select-label="''"
              ref="multiState"
              @select="selectStateFromMultiselect"
            >
                <span slot="noResult">Not found.</span>
            </multiselect>
          </form>
        </div>
        <div class="col col-lg-2" v-show="localStates && getEntityTypes(localStates)">
          <form>
            <label for="selectEntityTypes">Select Entity Type</label>
            <multiselect id="selectEntityTypes"
              v-model="localEntityType"
              :options="getEntityTypes(localStates)"
              :close-on-select="true"
              :hide-selected="true"
              :select-label="''"
              placeholder=""
              ref="multiEntity"
              @select="selectEntityFromMultiselect"
            >
              <span slot="noResult">Not found.</span>
            </multiselect>
          </form>
        </div>
        <div class="col col-lg-2" v-show="localEntityType && getFilingTypes(localStates, localEntityType)">
          <form>
            <label for="selectEntityTypes">Select Filing Type</label>
            <multiselect id="selectEntityTypes"
              v-model="localFilingTypes"
              :options="getFilingTypes(localStates, localEntityType)"
              :close-on-select="true"
              :hide-selected="true"
              :select-label="''"
              ref="multiFiling"
              @select="selectFilingFromMultiselect"
            >
                <span slot="noResult">Not found.</span>
            </multiselect>
          </form>
        </div>
        <div class="col col-lg-2" v-show="localFilingTypes && getServiceTypes(localStates, localEntityType, localFilingTypes)">
          <form>
            <label for="selectEntityTypes">Select Filing Type</label>
            <multiselect id="selectEntityTypes"
              v-model="localServiceTypes"
              :options="getServiceTypes(localStates, localEntityType, localFilingTypes)"
              :close-on-select="true"
              :hide-selected="true"
              :select-label="''"
              ref="multiService"
              @select="selectServiceFromMultiselect"
            >
                <span slot="noResult">Not found.</span>
            </multiselect>
          </form>
        </div>
        <div class="col-sm-auto align-self-end">
          <ZgButton class="zg-btn-outline zg-text-sm" @click="clearOptions" type="button">
            <template v-slot:title><i class="fa fa-times-circle"></i>&nbsp;Clear</template>
          </ZgButton>
        </div>
      </div>
      <div class="row my-4" v-if="showResults">
        <div class="col-4">
          <h5>Results</h5>
          <h2 class="header">{{ localStates }} - {{ localEntityType.toUpperCase() }} - {{ localFilingTypes | capitalizeAllWordsAndReplaceUnderscore }} - {{ localServiceTypes | capitalizeAllWordsAndReplaceUnderscore }}</h2>
          <div class="box">
            <div v-for="field in getSupportedFields(localStates, localEntityType, localFilingTypes, localServiceTypes)" v-bind:key="field">
              <dt>{{ field }}</dt>
              <dd>{{ reportFieldConfig[localStates][localEntityType][localFilingTypes][localServiceTypes][field] | yesno }}</dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { yesNoMixin, editAnnualReportConfMixin, capitalizeAndReplaceUnderscoreMixin } from '@/components/mixins';
import ZgButton from '@/components/common/Generics/ZgButton.vue'


export default {
  name: 'AmendmentReportFieldConfig',
  mixins: [yesNoMixin, editAnnualReportConfMixin, capitalizeAndReplaceUnderscoreMixin],
  components: {
    ZgButton
  },
  data() {
    return {
      localStates: undefined,
      localEntityType: undefined,
      localFilingTypes: undefined,
      localServiceTypes: undefined,
      showResults: undefined,
    };
  },
  computed: {
    ...mapGetters({
      reportFieldConfig: 'products/getReportFieldConfig',
    }),
    sortedStates() {
      return Object.keys(this.reportFieldConfig).sort();
    }
  },
  methods: {
    ...mapActions({
      fetchReportFieldConfig: 'products/fetchReportFieldConfig'
    }),
    clearOptions() {
      this.localStates = undefined;
      this.localEntityType = undefined;
      this.localFilingTypes = undefined;
      this.localServiceTypes = undefined;
      this.showResults = false;
    },
    async selectStateFromMultiselect() {
      this.localEntityType = undefined;
      this.localFilingTypes = undefined;
      this.localServiceTypes = undefined;
      this.showResults = false;
      this.$refs.multiEntity.activate();
    },
    async selectEntityFromMultiselect() {
      this.localFilingTypes = undefined;
      this.localServiceTypes = undefined;
      this.showResults = false;
      this.$refs.multiFiling.activate();
    },
    selectFilingFromMultiselect() {
      this.localServiceTypes = undefined;
      this.showResults = false;
      this.$refs.multiService.activate();
    },
    selectServiceFromMultiselect() {
      this.showResults = true;
    },
    clearFilters() {
      this.data = {
        service: undefined,
        state: undefined,
        county: undefined,
        entityType: undefined,
        filingType: undefined
      };
    },
    getEntityTypes(state) {
      if(state && this.reportFieldConfig[state]) {
        return Object.keys(this.reportFieldConfig[state]).sort();
      } else {
        return [];
      }
    },
    getFilingTypes(state, entityType) {
      if(state && entityType && this.reportFieldConfig[state][entityType]) {
        return Object.keys(this.reportFieldConfig[state][entityType]).sort();
      } else {
        return [];
      }
    },
    getServiceTypes(state, entityType, filingType) {
      if(state && entityType && filingType && this.reportFieldConfig[state][entityType][filingType]) {
        return Object.keys(this.reportFieldConfig[state][entityType][filingType]).sort();
      } else {
        return [];
      }
    },
    getSupportedFields(state, entityType, filingType, serviceType) {
      if(state && entityType && filingType && serviceType && this.reportFieldConfig[state][entityType][filingType][serviceType]) {
        return Object.keys(this.reportFieldConfig[state][entityType][filingType][serviceType]).sort();
      } else {
        return [];
      }
    }
  },
  created() {
    this.fetchReportFieldConfig();
  },
  mounted() {
    this.$refs.multiState.activate();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  .btn:first-child {
    margin: 0 5px 0 0;
  }
</style>
