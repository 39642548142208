<template>
<div>
  <div @click="showContent = !showContent">
    <h2 class="header">{{getJurisdictionName(jurisdiction.state)}} ({{ jurisdiction.state }}) <span class="jurisdiction_summary">{{jurisdiction.c_corp_fields.length}} Field / {{jurisdiction.c_corp_fees.length}} Fees</span></h2>
  </div>
  <div class="box" v-if="showContent">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h5>Fields</h5>
          <p class="general-description">These fields will be used to collect data from the users during the flow.</p>
          <p v-if="jurisdiction.c_corp_fields.length < 1" class="no-data">
            There are no fields for this jurisdiction.
          </p>
          <p v-else>
            <ul>
              <li v-for="field in jurisdiction.c_corp_fields" :key="field.uuid">{{field.display_name}}</li>
            </ul>
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <h5>Fees</h5>
          <p class="general-description">These fees will be used to collect data from the users during the flow.</p>
          <p v-if="jurisdiction.c_corp_fees.length < 1" class="no-data">
            There are no fees for this jurisdiction.
          </p>
          <p v-else>
            <ul>
              <li v-for="fee in jurisdiction.c_corp_fees" :key="fee.uuid">{{fee.fee_name}}</li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <ZgButton class="nav-link btn text-info btn-link" @click="openManageFields" type="button">
            <template v-slot:title><i class="fa fa-plus-circle"></i>&nbsp;Manage Fields</template>
          </ZgButton>
          <ServicesCCorpManageFields v-if="isManageFieldsModalOpen"  @close="isManageFieldsModalOpen = false" :jurisdiction="jurisdiction" :jurisdiction_name="getJurisdictionName(jurisdiction.state)" />
        </div>
        <div class="col-md-6 col-sm-12">

          <ZgButton class="nav-link btn text-info btn-link" @click="openManageFees" type="button">
            <template v-slot:title><i class="fa fa-plus-circle"></i>&nbsp;Manage Fees</template>
          </ZgButton>
          <ServicesCCorpManageFees v-if="isManageFeesModalOpen"  @close="isManageFeesModalOpen = false" :jurisdiction="jurisdiction" :jurisdiction_name="getJurisdictionName(jurisdiction.state)" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { stateObj } from '../utils/global-utils';
import ServicesCCorpManageFields from './services-c-corp-manage-fields.vue'
import ServicesCCorpManageFees from './services-c-corp-manage-fees.vue'
import ZgButton from '../common/Generics/ZgButton.vue'

export default {
  name: 'ServicesCCorpListItem',
  props: ['jurisdiction'],
  components: {
    ServicesCCorpManageFields,
    ServicesCCorpManageFees,
    ZgButton
  },
  data() {
    return {
      showContent: false,
      isManageFieldsModalOpen: false,
      isManageFeesModalOpen: false,
    };
  },
  methods: {
    getJurisdictionName(jurisdiction){
      return stateObj[jurisdiction]
    },
    openManageFields() {
      this.isManageFieldsModalOpen = true
    },
    openManageFees() {
      this.isManageFeesModalOpen = true
    },
  }
};
</script>

<style lang="scss" scoped>
  p {
    display: contents;
  }
  .header .jurisdiction_summary {
    font-size: rem(12);
    color: rgba($color: #e9e9e9, $alpha: 0.7);
    font-weight: 700;
  }
  .general-description{
    color: rgba($color: #8b8b8b, $alpha: 0.8);
    font-size: rem(10);
  }
  .no-data{
    color: rgba($color: #8b8b8b, $alpha: 0.9);
    font-weight: 800;
    display: block;
  }
  ul {
    list-style: none;
  }
  ul > li {
    font-weight: 800;
  }

</style>
