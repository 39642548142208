<template>
  <div>
    <vue-headful title="Manage Affiliates" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Manage Affiliates</h1>
        </div>
      </div>
      <div v-if="hasPermission('write:affiliates')" class="col-auto mb-2">
        <span class="top25"> <b>Select an affiliate as base affiliate</b></span>
        <select class="form-control-sm mx-2 mb-2 top25" v-model="existingAffiliate" required>
          <option :value=undefined disabled>Select an affiliate</option>
          <option v-for="affiliate in affiliates" :key="affiliate.uuid" :value="affiliate.name" >{{ affiliate.name }}</option>
        </select>
        <zg-button class="zg-btn-danger zg-text-m p-1" @click="deactivateAffiliate">
          <template v-slot:title>Deactivate Affiliate</template>
        </zg-button>
      </div>
      <div v-if="hasPermission('write:affiliates')" class="col-auto mb-2">
        <span class="top25"><b>Enter name of a new affiliate</b></span>
        <input class="form-control-sm mx-2 mb-2" type="text" placeholder="New affiliate name" v-model="newAffiliate" required/>
        <zg-button class="zg-btn-default zg-text-m p-1" @click="createAffiliate">
          <template v-slot:title>Create Affiliate</template>
        </zg-button>
      </div>
      <div class="row">
        <div class="col-auto mb-2">
          <small v-if="newAffiliateIsValid[1]" class="form-text text-danger">{{ newAffiliateIsValid[1] }}</small>
        </div>
      </div>
      <div class="row bottom-25">
        <div class="col-auto mb-2">
          <zg-button class="zg-btn-default" @click="showDeactivatedAffiliatesModal = true" :isEnable="true" :isSubmiting="false">
            <template v-slot:title>Show Deactivated Affiliates</template>
          </zg-button>
          <ShowDeactivatedAffiliateModal  v-if="showDeactivatedAffiliatesModal" @close="showDeactivatedAffiliatesModal = false" />
        </div>
      </div>

      <div class="col-auto mb-2">
        <AffiliateRelatedPricingList :filteredAffiliate ="existingAffiliate"/>
      </div>

      <div class="col-auto mb-2">
        <AffiliateRelatedPlanOptionsList :filteredAffiliate ="existingAffiliate"/>
      </div>
      <DeactivateAffiliateModal  :filteredAffiliate = "existingAffiliate" :filteredAffiliateUuid = "selectedAffiliateUuid" v-if="deactivateAffiliateModal" @close="deactivateAffiliateModal = false" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AffiliateRelatedPlanOptionsList from './affiliate-related-plan-options-list';
import AffiliateRelatedPricingList from './affiliate-related-pricing-list';
import DeactivateAffiliateModal from './deactivate-affiliate-modal';
import ShowDeactivatedAffiliateModal from './affiliate-show-deactivated-affiliate-modal';
import {hasPermissionMixin, inputValidationsMixin} from '../mixins.js';
import {DEFAULT_AFFILIATE} from "../../assets/constants/products";
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'CreateNewAffiliate',
  components: {
    AffiliateRelatedPlanOptionsList,
    AffiliateRelatedPricingList,
    DeactivateAffiliateModal,
    ShowDeactivatedAffiliateModal,
    ZgButton,
  },
  mixins: [
    inputValidationsMixin,
    hasPermissionMixin
  ],
  data() {
    return {
      newAffiliate: '',
      existingAffiliate: DEFAULT_AFFILIATE,
      filteredService: undefined,
      filteredInterval: undefined,
      deactivateAffiliateModal: false,
      selectedAffiliateUuid: undefined,
      showDeactivatedAffiliatesModal: false
    };
  },
  computed: {
    ...mapState({
      affiliates: (state) => state.products.affiliates
    }),
    newAffiliateIsValid() {
      const fieldName = 'New Affiliate';

      const valid = this.validValue(this.newAffiliate);
      if (!valid) return [valid, null];

      if (!/^[A-Za-z0-9_-]+$/.test(this.newAffiliate)) return [false, `${fieldName} must contain just numbers, letters, '-', '_'`];

      return [true, null];
    },
  },
  methods: {
    ...mapActions({
      createAffiliateEndPoint: 'products/createAffiliate',
      fetchAffiliatesV2: 'products/fetchAffiliatesV2'
    }),
    createAffiliate() {
      let data = {
        from_affiliate_name: this.existingAffiliate,
        new_affiliate_name: this.newAffiliate
      }
      this.createAffiliateEndPoint(data);
    },
    deactivateAffiliate() {
      let affiliate = this.affiliates.find(o => o.name === this.existingAffiliate);
      this.selectedAffiliateUuid = affiliate.uuid;
      this.deactivateAffiliateModal = true;
    },
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: false});
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: rem(10) 0 0 rem(10);
    font-size: rem(26);
    display: inline;
  }
  .top25{
    margin-top: rem(25);
  }
</style>
