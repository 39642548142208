<template>
  <div>
    <vue-headful title="Service Fees" />
    <div class="top25">
      <div class="row mb-2">
        <div class="col-auto">
          <h1>Service Fees</h1>
        </div>
      </div>
      <div class="row mb-2">
        <form class="form-inline ms-auto" v-on:submit="clearFilters">
          <div class="col-4">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Service Name" v-model="service"/>
          </div>
          <div class="col-4">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="State" v-model="state"/>
          </div>
          <div class="col-4">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="County" v-model="county"/>
          </div>
          <div class="col-4">
            <select class="form-control-sm mx-2 mb-2" type="text" v-model="entityType">
              <option value="undefined" disabled selected>Entity Type</option>
              <option value="">All</option>
              <option value="corp">Corp</option>
              <option value="llc">LLC</option>
              <option value="sole_prop">Sole Prop</option>
            </select>
          </div>
          <div class="col-4">
            <select class="form-control-sm mx-2 mb-2" type="text" v-model="filingType">
              <option value="undefined" disabled selected>Nationality</option>
              <option value="">All</option>
              <option value="domestic">Domestic</option>
              <option value="foreign">Foreign</option>
            </select>
          </div>
          <div class="col-4">
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </div>
        </form>
      </div>
      <sorted-table :values="filteredFees" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="display_name">Service Name</sort-link></th>
            <th><sort-link name="state">State</sort-link></th>
            <th><sort-link name="county">County</sort-link></th>
            <th><sort-link name="entity_type">Entity Type</sort-link></th>
            <th><sort-link name="filing_type">Nationality</sort-link></th>
            <th><sort-link name="amount">Amount</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <ServiceFeeListItem v-for="serviceFee in sort.values" :key="serviceFee.service_fee_uuid" :serviceFee="serviceFee" />
        </tbody>
      </sorted-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServiceFeeListItem from './service-fee-list-item';

export default {
  name: 'ServiceFeeList',
  components: {
    ServiceFeeListItem
  },
  data() {
    return {
      service: undefined,
      state: undefined,
      county: undefined,
      entityType: undefined,
      filingType: undefined
    };
  },
  computed: {
    ...mapState({
      serviceFees: (state) => state.products.serviceFees,
      products: (state) => state.orderCreate.allProducts
    }),
    filteredFees() {
      return this.serviceFees.filter((fee) => {
        if (this.service && !fee.display_name.toLowerCase().startsWith(this.service.toLowerCase())) {
          return false;
        }
        if (this.state && !fee.state.toLowerCase().startsWith(this.state.toLowerCase())) {
          return false;
        }
        if (fee.county !== '**' && (this.county && !fee.county.toLowerCase().startsWith(this.county.toLowerCase()))) {
          return false;
        }
        if (fee.entity_type !== '**' && (this.entityType && !fee.entity_type.toLowerCase().startsWith(this.entityType.toLowerCase()))) {
          return false;
        }
        if (fee.filing_type !== '**' && (this.filingType && !fee.filing_type.toLowerCase().startsWith(this.filingType.toLowerCase()))) {
          return false;
        }
        return true;
      });
    }
  },
  methods: {
    clearFilters() {
      this.data = {
        service: undefined,
        state: undefined,
        county: undefined,
        entityType: undefined,
        filingType: undefined
      };
    }
  },
  mounted() {
    this.$store.dispatch('products/fetchServiceFees');
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
  td.currency {
    text-align: center;
  }

</style>
