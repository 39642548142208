<template>
  <div>
    <vue-headful title="Affiliate Plans" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Affiliate Plans</h1>
          <div v-if="this.hasPermission('write:pricing')"
               class="btn btn-sm btn-primary action"
               @click="showCreateModal = true">Create</div>
          <AffiliatePlanOptionCreateModal v-if="showCreateModal"
                                          @close="showCreateModal = false"
                                          @affiliatePlanOptionCreated="handleAffiliatePlanOptionCreated" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <select class="form-control-sm mx-2 mb-2" v-model="filteredAffiliate">
              <option :value="undefined" disabled selected>Affiliate</option>
              <option :value="undefined">All</option>
              <option v-for="affiliate in affiliates" :key="affiliate.uuid" :value="affiliate.name" >{{ affiliate.name }}</option>
            </select>
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Plan" v-model="filteredPlan"/>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredPlanType">
              <option :value="undefined" disabled selected>Plan Type</option>
              <option :value="undefined">All</option>
              <option value="starter_plan">Starter</option>
              <option value="pro_plan">Pro</option>
              <option value="premium_plan">Premium</option>
            </select>
            <input class="form-control-sm mx-2 mb-2" type="number" placeholder="Version" v-model="filteredPlanVersion"/>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredPlans" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="related_affiliate.name">Affiliate</sort-link></th>
            <th><sort-link name="related_affiliate.uuid">Plan Option</sort-link></th>
            <th>Plan Pricing UUID</th>
            <th><sort-link name="related_plan_pricing.service">Plan</sort-link></th>
            <th><sort-link name="related_plan_pricing.amount">Amount</sort-link></th>
            <th><sort-link name="related_plan_pricing.coupon">Coupon</sort-link></th>
            <th v-if="hasPermission('write:affiliates')">Deactivate</th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <AffiliatePlanOptionListItem v-for="affiliatePlanOption in sort.values" :key="affiliatePlanOption.uuid" :affiliatePlanOption="affiliatePlanOption" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="affiliatePlanOptions.page" :totalPages="affiliatePlanOptions.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance, resource } from 'vuex-pagination';
import {mapActions, mapState} from 'vuex';
import get from 'lodash/get';
import Vue from 'vue';
import Pagination from '../utils/pagination';
import AffiliatePlanOptionListItem from './affiliate-plan-option-list-item';
import AffiliatePlanOptionCreateModal from './affiliate-plan-option-create-modal';
import { hasPermissionMixin } from '@/components/mixins';

// Enable sorting by nested attributes
Vue.prototype.$_ = {
  get
};

export default {
  name: 'AffiliatePlanOptionList',
  components: {
    AffiliatePlanOptionCreateModal,
    AffiliatePlanOptionListItem,
    Pagination
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
      filteredAffiliate: undefined,
      filteredPlan: undefined,
      filteredPlanType: undefined,
      filteredPlanVersion: undefined,
      showCreateModal: false
    };
  },
  computed: {
    ...mapState({
      affiliates: (state) => state.products.affiliates
    }),
    affiliatePlanOptions: createInstance('affiliatePlanOptions', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.filteredPlan) {
          params.related_plan_option_related_plan_pricing_service = this.filteredPlan;
        }
        if (this.filteredPlanType) {
          params.plan_type = this.filteredPlanType;
        }
        if (this.filteredPlanVersion) {
          params.plan_version = this.filteredPlanVersion;
        }
        if (this.filteredAffiliate) {
          params.related_affiliate_name = this.filteredAffiliate;
        }
        return params;
      }
    }),
    filteredPlans() {
      return this.affiliatePlanOptions.items;
    }
  },
  methods: {
    ...mapActions({
      fetchAffiliatesV2: 'products/fetchAffiliatesV2'
    }),
    clearFilters() {
      this.data = {
        filteredAffiliate: undefined,
        filteredPlan: undefined,
        filteredPlanType: undefined
      };
    },
    handleAffiliatePlanOptionCreated() {
      this.showCreateModal = false;
      // Refresh the cache of plan options now that there's a new one
      resource('affiliatePlanOptions').refresh();
    }
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: false});
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
