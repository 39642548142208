<template>
    <div class="modal">
     <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Product Attributes - {{product.service}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click=" $emit('close')"></button>
          </div>
          <div class="modal-body">
            <tr>
              <td>Service</td>
              <td>{{ product.service }}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{{ product.description}}</td>
            </tr>
            <tr>
              <td>Display Name</td>
              <td>{{ product.display_name }}</td>
            </tr>
            <tr>
              <td>Active?</td>
              <td>{{ product.active_flag}}</td>
            </tr>
            <tr>
              <td>Category?</td>
              <td>{{ product.category }}</td>
            </tr>
            <tr>
              <td>Product category</td>
              <td>{{ product.product_category }}</td>
            </tr>
            <tr>
              <td>Product subcategory</td>
              <td>{{ product.subcategory }}</td>
            </tr>
            <tr>
              <td>Recurring?</td>
              <td>{{ product.recurring }}</td>
            </tr>
            <tr>
              <td>Requires activation?</td>
              <td>{{ product.requires_activation }}</td>
            </tr>
            <tr>
              <td>Is vanity?</td>
              <td>{{ product.is_vanity }}</td>
            </tr>
            <tr>
              <td>Requires delivery?</td>
              <td>{{ product.requires_delivery }}</td>
            </tr>
          </div></div></div></div>
</template>

<script>
export default {
  name: 'ServiceProductsListItemModal',
  props: ['product']
};
</script>
