<template>
 <div class="modal">
   <div class="modal-dialog modal-dialog-centered">
     <div class="modal-content min-width-fit-content">
      <div class="modal-header">
        <h5 class="modal-title">Deactivate Affiliate</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to deactivate the affiliate '{{filteredAffiliate}}'</p>
      </div>
      <div class="modal-footer">
        <zg-button class="zg-btn-default" @click="$emit('close')" :isEnable="true" :isSubmiting="false">
            <template v-slot:title>NO</template>
        </zg-button>
        <zg-button class="zg-btn-danger" @click="deactivate();" :isEnable="true" :isSubmiting="false">
            <template v-slot:title>YES</template>
        </zg-button>
      </div>
     </div>
   </div>
  </div>
</template>

<script>


import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'DeactivateAffiliateModal',
  components:{
    ZgButton,
  },
  props: ['filteredAffiliate', 'filteredAffiliateUuid'],
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
    deactivate() {
      let data = {
        affiliate_partner_uuid: this.filteredAffiliateUuid
      }
      this.$store.dispatch('products/deactivateAffiliate', data)
        .then(() => {

        });
    },
  }
};
</script>
