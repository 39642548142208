<template>
  <tr>
    <td>{{ serviceFee.display_name }}</td>
    <td>{{ serviceFee.state }}</td>
    <td>{{ serviceFee.county }}</td>
    <td>{{ serviceFee.entity_type }}</td>
    <td>{{ serviceFee.filing_type }}</td>
    <td class="currency">{{ this. money(serviceFee.amount) }}</td>
  </tr>
</template>

<script>
import { moneyMixin } from '@/components/mixins';

export default {
  name: 'ServiceFeeListItem',

  props: ['serviceFee', 'displayName'],

  mixins: [moneyMixin]
};
</script>

<style lang="scss">
  td.currency {
    text-align: right;
  }
</style>
