<template>
  <tr>
    <td>{{ servicePricing.display_name }}</td>
    <td>{{ servicePricing.service_pricing_uuid }}</td>
    <td>{{ servicePricing.service }}</td>
    <td>{{ servicePricing.interval }}</td>
    <td>{{ this.money(servicePricing.amount) }}</td>
    <td>{{ servicePricing.coupon_name }}</td>
    <td>{{ servicePricing.defer_until_formation_complete }}</td>
    <td v-if="hasPermission('write:pricing') && !readOnly">
      <!-- Deactivation Controls -->
      <div>
        <span v-if="!editMode"><button v-on:click="editMode = true" class="btn btn-danger" >Deactivate</button></span>
        <span v-else>
          <button class="btn btn-primary" v-on:click="deactivatePricing">Save</button>
          <button v-on:click="editMode = false" class="btn btn-secondary" >Cancel</button>
          </span>
      </div>
    </td>
  </tr>
</template>

<script>
import { resource } from 'vuex-pagination';
import { moneyMixin, hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'ServicePricingListItem',
  props: {
    servicePricing: {
      required: true
    },
    readOnly: {
      default: false
    }
  },
  mixins: [moneyMixin, hasPermissionMixin],
  data() {
    return {
      editMode: false
    };
  },
  methods: {
    deactivatePricing() {
      const pricingData = {
        uuid: this.servicePricing.service_pricing_uuid,
        active: false
      };
      this.$store.dispatch('products/updateServicePricingV2', pricingData).then(() => {
        this.editMode = false;
        resource('servicePricing').refresh();
      });
    }
  }
};
</script>

<style scoped>
  .btn {
    padding-bottom: 0px;
    padding-top: 0px;
  }
</style>
