<template>
  <div>
    <form class="mt-2">
      <div class="form-row">
        <div class="form-group col col-md-5">
          <strong v-if="isEdited">EDITING: {{calculatorModel.displayName}} ({{calculatorModel.name}})</strong>
          <strong v-else>CREATING new calculator</strong>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col col-md-5">
          <label for="nameCalculator">Name</label>
          <input v-model="calculatorModel.name" @change="isValidCalculator" type="text" class="form-control" id="nameCalculator" aria-describedby="nameCalculatorHelp" placeholder="Enter name">
          <small id="nameCalculatorHelp" class="form-text text-muted">Name used to connect to other models.</small>
        </div>
        <div class="form-group col col-md-5">
          <label for="displayNameCalculator">Display Name</label>
          <input v-model="calculatorModel.displayName" @change="isValidCalculator" type="text" class="form-control" id="displayNameCalculator" aria-describedby="displayNameCalculatorHelp" placeholder="Enter Display Name">
          <small id="displayNameCalculatorHelp" class="form-text text-muted">Friendly name of calculator.</small>
        </div>
      </div>
      <div class="form-check">
        <label class="form-check-label" for="activeCalculator">Active?</label>
        <input v-model="calculatorModel.active" @change="isValidCalculator" type="checkbox" class="form-check-input" id="activeCalculator">
      </div>
      <div class="form-group">
        <label for="descriptionCalculator">Description</label>
        <textarea v-model="calculatorModel.description" @change="isValidCalculator" class="form-control" id="descriptionCalculator" rows="2"></textarea>
      </div>
      <div class="form-group">
        <label for="productCalculator">Product associated</label>
        <select v-model="calculatorModel.productType" @change="isValidCalculator" class="form-control" id="productCalculator">
          <option>Select Product</option>
          <option v-for="product in this.getListProducts" :key="product.service_product_uuid" :value="product.service_product_uuid">
            {{ product.service }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="jurisdictionDefinitionCalculator">Jurisdiction Definition</label>
        <select v-model="calculatorModel.jurisdictionDefinition" @change="isValidCalculator" class="form-control" id="jurisdictionDefinitionCalculator">
          <option>
            Select State
          </option>
          <option v-for="jurisdiction in getJurisdictionStates" :key="jurisdiction.jurisdiction_definition_uuid" :value="jurisdiction.jurisdiction_definition_uuid">
            {{ jurisdiction.state }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="rulesCalculator">Rules</label>
        <textarea v-model="calculatorModel.rules" @change="isValidCalculator" class="form-control" id="rulesCalculator" rows="5"></textarea>
      </div>
      <div class="form-row">
        <div class="form-group col col-md-5">
          <b>Current Fees</b>
          <ul>
            <li v-for="fee in calculatorModel.selectedFees" v-bind:key="fee.uuid">{{fee.fee_name}}</li>
          </ul>
          <ZgButton @click="this.openFeesModal" :class="'zg-btn-default'" :disabled="!isValid">
            <template v-slot:title>
              <div v-if="!isEdited">Add Fees</div>
              <div v-else>Edit Fees</div>
            </template>
          </ZgButton>
        </div>
        <div class="form-group col col-md-5">
          <b>Current Fields</b>
          <ul>
            <li v-for="field in calculatorModel.selectedFields" v-bind:key="field.uuid">{{field.display_name}}</li>
          </ul>
          <ZgButton @click="this.openFieldsModal" :class="'zg-btn-default'" :disabled="!isValid">
            <template v-slot:title>
              <div v-if="!isEdited">Add Fields</div>
              <div v-else>Edit Fields</div>
            </template>
          </ZgButton>
        </div>
      </div>
      <ZgButton @click="this.createEditCalculator" :class="'zg-btn-default'" :disabled="!isValid">
        <template v-slot:title>
          <div v-if="!isEdited">Create</div>
          <div v-else>Save Edits</div>
        </template>
      </ZgButton>
      <ServicesCCorpCalculatorCreateEditModal v-if="showConfirmModal" :origCalculator="calculator"
                                              @closeModal="showConfirmModal=false; hasConfirmed=false"
                                              @confirmed="confirmationFromModal" />
      <AddRemoveModal v-if="showFeesModal" propertyName="Fees"
                      keyName="uuid" valueName="fee_name"
                      :propertyPopulation="fees" :propertiesOnObject="calculatorModel.selectedFees"
                      @closeModal="showFeesModal=false"
                      @saveChanges="showFeesModal=false; calculatorModel.selectedFees = $event"
      />
      <AddRemoveModal v-if="showFieldsModal" propertyName="Fields"
                      keyName="uuid" valueName="display_name"
                      :propertyPopulation="fields" :propertiesOnObject="calculatorModel.selectedFields"
                      @closeModal="showFieldsModal=false"
                      @saveChanges="calculatorModel.selectedFields = $event; showFieldsModal=false"
      />
    </form>
    <ServicesCCorpCalculatorCompute
      v-if="calculator && isEdited"
      :calculator="calculator"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import { hasPermissionMixin } from '@/components/mixins';
import ServicesCCorpCalculatorCreateEditModal from './ServicesCCorpCalculatorCreateEditModal'
import ServicesCCorpCalculatorCompute from './ServicesCCorpCalculatorCompute';
import AddRemoveModal from '../common/AddRemoveModal'

export default {
  name: 'ServicesCCorpCalculatorCreateEdit',
  components: {
    AddRemoveModal,
    ZgButton,
    ServicesCCorpCalculatorCreateEditModal,
    ServicesCCorpCalculatorCompute,
  },
  props: {
    calculator: {
      type: Object,
      optional: true
    }
  },
  mixins: [hasPermissionMixin],
  data(){
    return {
      defaultCalculator: {
        uuid: null,
        name: '',
        displayName: '',
        active: false,
        description: '',
        productType: 'Select Product',
        jurisdictionDefinition: 'Select State',
        selectedFees: [],
        selectedFields: [],
        rules: '',
      },
      isValid: false,
      showConfirmModal: false,
      hasConfirmed: false,
      showFeesModal: false,
      showFieldsModal: false
    }
  },
  methods: {
    ...mapActions({
      fetchJurisdictionStates: 'products/fetchJurisdictionStates',
      fetchAllProducts: 'products/fetchAllProducts',
      createCCorpCalculator: 'products/createCCorpCalculator',
      updateCCorpCalculator: 'products/updateCCorpCalculator',
      addFeeToCCorpCalculator: 'products/addFeeToCCorpCalculator',
      removeFeeFromCCorpCalculator: 'products/removeFeeFromCCorpCalculator',
      addFieldToCCorpCalculator: 'products/addFieldToCCorpCalculator',
      removeFieldFromCCorpCalculator: 'products/removeFieldFromCCorpCalculator',
      fetchUserEmail: 'users/fetchUserEmail',
      fetchFees: 'products/fetchCCorpFees',
      fetchFields: 'products/fetchCCorpFields'
    }),
    ...mapGetters({
      getProducts: 'products/getProducts',
      getUserEmail: 'users/getUserEmail',
      getListCCorpStockCalculators: 'products/'
    }),
    openManageTerms() {
      this.isManageTermsModalOpen = true
    },
    createEditCalculator() {
      const calculator = {
        "name": this.calculatorModel.name,
        "active": this.calculatorModel.active,
        "display_name": this.calculatorModel.displayName,
        "description": this.calculatorModel.description,
        "product_type_uuid": this.calculatorModel.productType,
        "jurisdiction_definition_uuid": this.calculatorModel.jurisdictionDefinition,
        "calculation_rules": this.calculatorModel.rules,
        "changed_by": this.getUserEmail()
      };
      let promiseChain = null
      if (this.isEdited)
      {
        // Confirmation only required on edits
        if (!this.hasConfirmed) {
          this.showConfirmModal = true;
          return false
        } else {
          promiseChain = this.updateCCorpCalculator({calculator, uuid: this.calculatorModel.uuid})
        }
      } else {
        promiseChain = this.createCCorpCalculator({calculator})
      }
      // process fees
      promiseChain
        .then(({successful, uuid}) => {
        if (successful) {
          let {additions, removals} = this.determineChanges('fees')
          additions.forEach(f => this.addFeeToCCorpCalculator({ccorpCalculatorUuid: uuid, feeUuid: f.uuid}))
          removals.forEach(f => this.removeFeeFromCCorpCalculator({ccorpCalculatorUuid: uuid, feeUuid: f.uuid}))
        }
        return {successful, uuid}
      })
        // process fields
        .then(({successful, uuid}) => {
          if (successful) {
            let {additions, removals} = this.determineChanges('fields')
            additions.forEach(f => this.addFieldToCCorpCalculator({ccorpCalculatorUuid: uuid, fieldUuid: f.uuid}))
            removals.forEach(f => this.removeFieldFromCCorpCalculator({ccorpCalculatorUuid: uuid, fieldUuid: f.uuid}))
          }
          return {successful, uuid}
        })
        .finally(() => {
          this.$emit('close')
        })


    },
    isValidCalculator() {
      let isValid = true
      isValid = isValid && this.calculatorModel.name!==''
      isValid = isValid && this.calculatorModel.displayName!==''
      isValid = isValid && this.calculatorModel.jurisdictionDefinition!==''
      isValid = isValid && this.calculatorModel.productType!==''
      isValid = isValid && this.calculatorModel.description!==''
      isValid = isValid && this.calculatorModel.rules!==''
      this.isValid = isValid
    },
    confirmationFromModal() {
      this.hasConfirmed=true;
      this.showConfirmModal=false
      this.createEditCalculator()
    },
    openFeesModal() {
      this.fetchFees(this.calculatorModel.jurisdictionDefinition)
        .then(() => this.showFeesModal = true)
    },
    openFieldsModal() {
      this.fetchFields({jurisdiction: this.calculatorModel.jurisdictionDefinition})
        .then(() => this.showFieldsModal = true)
    },
    determineChanges(type) {
      let selected = null
      let previouslySelected = null
      if (type === 'fees') {
        selected = this.calculatorModel.selectedFees
        previouslySelected = this.calculator.c_corp_fees
      }
      else if(type === 'fields') {
        selected = this.calculatorModel.selectedFields
        previouslySelected = this.calculator.c_corp_fields
      }
      else {
        return null
      }
      const additions = selected.filter(p => !previouslySelected.includes(p)) || []
      const removals = previouslySelected.filter(p => !selected.includes(p)) || []
      return {additions, removals}
    },
  },
  computed: {
    ...mapState({
      fees: state => state.products.ccorpFees,
      fields: state => state.products.ccorpFields
    }),
    calculatorModel() {
      if (Object.keys(this.calculator).length === 0) {
        return this.defaultCalculator
      } else {
        // Transform zapi calculator into format for ZenGarden
        let state = this.calculator.jurisdiction_definition_name_id.split('_')
        state = state[state.length-1]
        const jd = this.getJurisdictionStates.find(jd => jd.state === state)
        let jd_uuid = this.defaultCalculator.jurisdictionDefinition
        if (jd) {
          jd_uuid = jd.jurisdiction_definition_uuid
        }

        const product = this.getListProducts.find(p => p.service === this.calculator.product_type)
        let product_uuid = this.defaultCalculator.productType
        if (product) {
          product_uuid = product.service_product_uuid
        }
        return {
          uuid: this.calculator.uuid,
          name: this.calculator.name,
          displayName: this.calculator.display_name,
          active: this.calculator.active,
          description: this.calculator.description,
          productType: product_uuid,
          jurisdictionDefinition: jd_uuid,
          rules: this.calculator.calculation_rules,
          selectedFees: this.calculator.c_corp_fees,
          selectedFields: this.calculator.c_corp_fields,
        }
      }
    },
    isEdited() {
      return !!this.calculatorModel.uuid
    },
    getJurisdictionStates() {
      return this.$store.state.products.jurisdictionStates;
    },
    getListProducts() {
      return this.getProducts();
    }
  },
  created() {
    this.fetchJurisdictionStates()
    this.fetchAllProducts()
    this.fetchUserEmail()
  },
  beforeUpdate() {
    this.isValidCalculator()
  }
};
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    header {
      font-size: rem(20);
      font-weight: bold;
      padding: 1em 0 1em 0;
    }
    .zg-card {
      margin-bottom: 1em;

      header {
        font-size: rem(18);
        font-weight: bold;
        padding: 0.5em 0 0.5em 0;
        &:hover {
          cursor: pointer
        }
      }
      .form-group {
        padding: 0 0 1em 0;
      }
      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  section {
    margin-top: 3em;
  }
</style>
