
<template>
  <section>
    <div class="row"><h4>Compute</h4></div>
    <div class="row mt-2">
      <div class="col">
        <form>
          <div class="form-group row my-0 py-1 px-3" v-for="(field, index) in calculator.c_corp_fields" :key="field.uuid">
            <label :for="`${field.display_name} ID`" class="col-6 col-form-label">{{field.display_name}}</label>
            <div class="col-4">
              <input v-model="inputs[index]" 
                :id="`${field.display_name} ID`"
                :type="getTypeAndClassInputCCorpField(field).type"
                :class="getTypeAndClassInputCCorpField(field).class"
                />
            </div>
          </div>
          <ZgButton class="my-2" :class="'zg-btn-default'" @click="sendButtonClick" :isSubmitting="this.isLoading">
            <template v-slot:title>Send</template>
          </ZgButton>
        </form>
      </div>
      <div class="col">
        <div class="row" v-if="this.outputCalculator">
          <h6>Response:</h6>
          <vue-json-pretty class="preview-box" :data="this.outputCalculator"></vue-json-pretty>
        </div>
      </div>
    </div>
  </section>  
</template>

<script>
import {mapActions} from 'vuex';
import { hasPermissionMixin } from '@/components/mixins';
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'ServicesCCorpCalculatorCompute',
  components: {
    ZgButton,
    VueJsonPretty,
  },
  props: {
    calculator: {
      type: Object
    }
  },
  mixins: [hasPermissionMixin],
  data(){
    return {
      outputCalculator: null,
      isLoading: false,
    }
  },
  methods: {
    ...mapActions({
      computeCCorpCalculator: 'products/computeCCorpCalculator',
    }),
    getTypeAndClassInputCCorpField(field) {
      const typeField = field.field_type;
      if(typeField === 'number') {
        return { type: 'number', class: 'form-control'};
      } else if(typeField === 'boolean') {
        return { type: 'checkbox', class: 'form-check-input'};
      } else {
        return { type: 'text', class: 'form-control'};
      }
    },
    sendButtonClick() {
      this.outputCalculator = null;
      const payload = { calculator: this.calculator.name };
      for (const [index, field] of this.calculator.c_corp_fields.entries()) {
        payload[field.term] = this.inputs[index];
      }
      this.isLoading = true;
      this.computeCCorpCalculator(payload)
      .then((response)=>{
        this.outputCalculator = response.data;
      })
      .catch((e) => {
        this.outputCalculator = e.response.data;
      })
      .finally(()=>{
        this.isLoading = false;
      });
    },
    reset() {
      if(this.calculator.c_corp_fields) {
        const numberFields = this.calculator.c_corp_fields.length;
        this.inputs = Array(numberFields).fill("");
      }
      this.outputCalculator = null;
    }
  },
  watch: {
    'calculator': function() {
      this.reset();
    }
  },
  created() {
    this.reset();
  }
}
</script>

<style>

</style>