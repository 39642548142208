<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <h5 class="modal-title">Create new Affiliate-Plan Option Link</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-row">
            <div class="form-group col-md">
              <label>
                Affiliate
                <select class="form-control" v-model="affiliateUuid">
                  <option v-for="affiliate in affiliates"
                          v-bind:key="affiliate.uuid"
                          v-bind:value="affiliate.uuid">{{ affiliate.name }}</option>
                </select>
              </label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md">
              Plan Option
              <div class="btn btn-sm btn-warning action"
                   @click="showPlanOptionSelectModal = true">Select Plan Option</div>
              <PlanOptionSelectModal v-if="showPlanOptionSelectModal"
                                     @planOptionSelected="handlePlanOptionSelected"
                                     @close="showPlanOptionSelectModal = false" />
              <div v-if="this.planOption">
                {{ this.planOption.related_plan_pricing.service }}
                ({{ this.money(this.planOption.related_plan_pricing.amount) }})</div>
              <div v-else>
                None selected.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="!isFormValid"
                  @click="handleCreateAffiliatePlanOption">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import PlanOptionSelectModal from './plan-option-select-modal';
import { moneyMixin } from '../mixins';

export default {
  name: 'AffiliatePlanOptionCreateModal',
  components: { PlanOptionSelectModal },
  mixins: [moneyMixin],
  computed: {
    ...mapState({
      affiliates: (state) => state.products.affiliates
    }),
    isFormValid() {
      return (this.affiliateUuid !== null) && (this.planOption !== null);
    }
  },
  data() {
    return {
      affiliateUuid: null,
      planOption: null,
      showPlanOptionSelectModal: false
    };
  },
  methods: {
    ...mapActions({
      fetchAffiliatesV2: 'products/fetchAffiliatesV2'
    }),
    handlePlanOptionSelected(selectedPlanOption) {
      this.planOption = selectedPlanOption;
      this.showPlanOptionSelectModal = false;
    },
    handleCreateAffiliatePlanOption() {
      const newApo = {
        affiliate: this.affiliateUuid,
        plan_option: this.planOption.uuid
      };
      this.$store.dispatch('products/createAffiliatePlanOption', newApo)
        .then(() => {
          this.$emit('affiliatePlanOptionCreated');
        });
    }
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: false});
  }
};
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
  }
</style>
