<template>
  <div
    class="zg-card" 
    :class="{
      'zg-card-col': orientation=='col',
      'zg-card-row': orientation=='row'
      }"
  >
    <slot>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'ZgCard',
  props: ['orientation']
}
</script>
<style lang="scss" scoped>
  .zg-card {
    display: flex;
    padding: em(40);
    border-radius: em(10);
    box-shadow: 0 em(1) em(2) em(1) rgba($clr-black, 0.1);
    transition: 0.3s;

    &:hover {
      box-shadow: 0 em(1) em(4) em(2) rgba($clr-black, 0.1);
    }
    &-row {
      flex-direction: row;
      align-items: center;
    }
    &-col {
      flex-direction: column;
      justify-content: center;
    }
    
  }
</style>