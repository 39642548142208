<template>
  <Modal @closeModal="$emit('closeModal')">
    <template v-slot:title>Add/Remove {{propertyName}}</template>
    <template v-slot:body>
      <form>
        <label for="property_select">{{propertyName}}</label>
        <multiselect
          v-model="propertiesSelected"
          name="property_select"
          :label="valueName"
          :placeholder="placeholder"
          :track-by="keyName"
          :close-on-select="false"
          :hide-selected="true"
          :multiple="true"
          :options="propertyPopulation">
        </multiselect>
        <ZgButton class="zg-btn-default zg-text-sm" @click="emitChanges" type="button">
          <template v-slot:title><i class="fa fa-save"></i>&nbsp;Select</template>
        </ZgButton>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'
import ZgButton from './Generics/ZgButton'

export default {
  name: 'AddRemoveModal',
  components: {Modal, ZgButton},
  props: ['propertyName', 'propertyPopulation', 'keyName', 'valueName', 'propertiesOnObject'],
  data(){
    return {
      propertiesSelected: [],
      propertiesToRemove: [],
      propertiesToPick: []
    }
  },
  methods: {
    emitChanges() {
      this.$emit('saveChanges', this.propertiesSelected)
    }
  },
  computed: {
    placeholder() {
      return "Select " + this.propertyName
    }
  },
  created() {
    this.propertiesSelected = this.propertiesOnObject || []
  }
}
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.modal {
  &-dialog {
    &--full-width {
      @include mq($from: xs) {
        max-width: 96vw !important;
      }
    }
  }
  &-body {
    overflow-y: auto;
    max-height: 70vh;
  }
  &-header, &-footer {
    background: rgba($stone-5, .25);
  }
}
</style>
