<template>
  <tr>
    <td>{{ calculator.name }}</td>
    <td>{{ calculator.display_name }}</td>
    <td>{{ calculator.active | yesno }}</td>
    <td>{{ calculator.product_type }}</td>
    <td>{{ calculator.jurisdiction_definition_name_id }}</td>
    <td>
      <zg-button class="zg-text-sm" @click="$emit('view', calculator)">
        <template v-slot:title>View</template>
      </zg-button>
    </td>
  </tr>
</template>

<script>
import ZgButton from '../common/Generics/ZgButton.vue'
import { yesNoMixin } from '@/components/mixins';

export default {
  name: 'CCorpCalculatorListItem',
  props: ['calculator'],
  mixins: [yesNoMixin],
  components: {
    ZgButton
  },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

<style lang="scss">
  td {
    vertical-align: middle;
  }
</style>
