<template>
   <div class="modal">
     <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Show deactivated affiliates</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Affiliate</th>
                        <th v-if="hasPermission('write:affiliates')" scope="col">Activate?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="affiliate in deactivated_affiliates" :key="affiliate.uuid" :value="affiliate.name">
                        <td scope="row">{{affiliate.name}}</td>
                        <td v-if="hasPermission('write:affiliates')">
                            <zg-button class="zg-btn-default" @click="activateAffiliate(affiliate.uuid)" :isEnable="true" :isSubmiting="false">
                              <template v-slot:title>Activate</template>
                            </zg-button>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
          <div class="modal-footer">

          </div>
       </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import remove from 'lodash/remove';
import {hasPermissionMixin} from "@/components/mixins";
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'ShowDeactivatedAffiliateModal',
  components: {
    ZgButton,
  },
  mixins: [
    hasPermissionMixin
  ],
  computed: {
      ...mapState({
        deactivated_affiliates: state => state.products.deactivated_affiliates
      }),
  },
  methods: {
    ...mapActions({
      activateAffiliateEndPoint: 'products/activateAffiliate',
      setDeactivatedAffiliates: 'products/setDeactivatedAffiliates',
      fetchAffiliatesV2: 'products/fetchAffiliatesV2',
    }),
    activateAffiliate(affiliate_uuid) {
      let data = {
        affiliate_partner_uuid: affiliate_uuid,
      }

      this.activateAffiliateEndPoint(data).finally(() => {
        let da = remove(this.deactivated_affiliates, function(affiliate) {
            return affiliate.uuid != affiliate_uuid;
        });
        this.setDeactivatedAffiliates({deactivated_affiliates: da});
      });
    }
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: true});
  }
};
</script>
