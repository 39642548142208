<template>
  <div class="zg-card-column">
    <slot name="content">
    </slot>
  </div>
</template>
<script>
export default {
  name: 'ZgCardColumn'
}
</script>
<style lang="scss" scoped>
  .zg-card-column {
    margin-top: em(20);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: em(40);
    border-radius: em(10);
    box-shadow: 0 em(1) em(2) em(1) rgba($clr-black, 0.1);
    transition: 0.3s;

    &:hover {
      box-shadow: 0 em(1) em(4) em(2) rgba($clr-black, 0.1);
    }
  }
</style>
