import { render, staticRenderFns } from "./affiliate-plan-option-list-item.vue?vue&type=template&id=2bb573df&scoped=true"
import script from "./affiliate-plan-option-list-item.vue?vue&type=script&lang=js"
export * from "./affiliate-plan-option-list-item.vue?vue&type=script&lang=js"
import style0 from "./affiliate-plan-option-list-item.vue?vue&type=style&index=0&id=2bb573df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb573df",
  null
  
)

export default component.exports