<template>
  <tr>
    <td>{{ serviceProductPlan.plan.service }}</td>
    <td>{{ serviceProductPlan.plan.display_name }}</td>
    <td>
      <ul>
        <ServiceProductPlanListItemProduct v-for="serviceProduct in serviceProductPlan.products" :key="serviceProduct.product_name" :serviceProduct="serviceProduct" :readOnly="true"/>
      </ul>
    </td>
  </tr>
</template>

<script>
import ServiceProductPlanListItemProduct from './service-product-plans-list-item-product';

export default {
  name: 'ServiceProductPlanListItem',
  components: {
    ServiceProductPlanListItemProduct
  },
  props: ['serviceProductPlan']
};
</script>
