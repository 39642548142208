<template>
  <div>
    <zg-card-column>
      <template v-slot:content>
        <div class="container">
          <div class="row justify-content-between mb-2">
            <div class="col-auto mb-2">
              <h1 v-if="filteredAffiliate == undefined">Affiliate Plan Options</h1>
              <h1 v-else>Affiliate Plan Options for '{{filteredAffiliate}}'</h1>
            </div>
          </div>
          <sorted-table :values="filteredPlans" class="table table-sm">
            <thead>
              <tr>
                <th><sort-link name="related_affiliate.uuid">Plan Option</sort-link></th>
                <th><sort-link name="related_plan_pricing.service">Plan</sort-link></th>
                <th><sort-link name="related_plan_pricing.amount">Amount</sort-link></th>
                <th><sort-link name="related_plan_pricing.coupon">Coupon</sort-link></th>
              </tr>
            </thead>
            <tbody slot="body" slot-scope="sort">
              <AvailablePlanOptionListItem v-for="availablePlan in sort.values" :key="availablePlan.uuid" :affiliatePlanOption="availablePlan" />
            </tbody>
          </sorted-table>
          <Pagination :page.sync="affiliatePlanOptions.page" :totalPages="affiliatePlanOptions.totalPages" />
        </div>
      </template>
    </zg-card-column>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import get from 'lodash/get';
import Vue from 'vue';
import Pagination from '../utils/pagination';
import AvailablePlanOptionListItem from './available-plan-option-list-item'
import { hasPermissionMixin } from '../mixins';
import ZgCardColumn from "@/components/common/Generics/ZgCardColumn";

// Enable sorting by nested attributes
Vue.prototype.$_ = {
  get
};

export default {
  name: 'AffiliateRelatedPlanOptionsList',
  props: ['filteredAffiliate'],
  components: {
    AvailablePlanOptionListItem,
    Pagination,
    ZgCardColumn
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
    };
  },
  computed: {
    affiliatePlanOptions: createInstance('affiliatePlanOptions', {
      page: 1,
      pageSize: 10,
      args() {
        const params = {}
        if (this.filteredAffiliate) {
          params.related_affiliate_name = this.filteredAffiliate;
        }
        return params;
      }
    }),
    filteredPlans() {
      return this.affiliatePlanOptions.items;
    }
  }
};
</script>
