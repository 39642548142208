<template>
    <div class="modal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">WARNING</h3>
                    <button type="button" class="btn-close" @click="$emit('showDeactivateModal')" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>You are about to deactivate a plan. <br><br> This plan will no longer be available for customers for purchase or accessible in ZenGarden. If removed by mistake, it will have to be reenabled by the eCommerce Product Definition team. <br><br> Are you sure you want to deactivate the Affiliate Plan Option?</p>
                </div>
                <div class="modal-footer">
                    <ZgButton class="zg-btn-danger" @click="handleDeactivation">
                        <template v-slot:title>Yes, Deactivate Plan</template>
                    </ZgButton>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {mapActions} from 'vuex'
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
    name: 'AffiliatePlanOptionDeactivateModal',
    components: { ZgButton },
    props: ['affiliatePlanOptionUUID'],
    methods: {
        ...mapActions({
            deactivateAffiliatePlan: 'products/deactivateAffiliatePlan'
        }),
        handleDeactivation() {
            this.deactivateAffiliatePlan({affiliatePlanOptionUUID: this.affiliatePlanOptionUUID})
            .finally(() => this.$emit('affiliatePlanDeactivated'))
        }
    }
}

</script>