<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Save Changes?</h5>
          <button type="button" class="btn-close" @click="$emit('closeModal')" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to save the changes for {{origCalculator.display_name}} ({{origCalculator.name}})</p>
        </div>
        <div class="modal-footer">
          <ZgButton class="zg-btn-light" @click="$emit('closeModal')"><template v-slot:title>Cancel</template></ZgButton>
          <ZgButton class="zg-btn-default" @click="$emit('confirmed')" :isEnable="true" :isSubmitting="false">
            <template v-slot:title>Save Edits</template>
          </ZgButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'ServicesCCorpCalculatorCreateEditModal',
  components:{
    ZgButton,
  },
  data() {
    return {
    };
  },
  props: {
    origCalculator: {
      type: Object,
      optional: false
    }
  },
  computed: {

  },
  methods: {

  }

};
</script>

<style lang="scss" scoped>
.badge {
  text-transform: capitalize;
}

</style>
