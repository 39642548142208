<template>
  <tr>
    <td>
      <div class="plan-name" >
        <div class="arrow-box" v-on:click="toggleContent"><i class="arrow" v-bind:class="arrowClass" ></i></div>
        {{ planOption.related_plan_pricing.service }}
      </div>
      <div v-if="showContent">
        <br>
        <table class="table table-sm">
          <thead>
            <th>Product</th>
            <th>Product ID</th>
            <th>Interval</th>
            <th>Amount</th>
            <th>Coupon</th>
            <th>Is Deferred?</th>
          </thead>
          <tbody>
              <PlanListItem v-for="product_pricing in planOption.contents" :key="product_pricing.service_pricing_uuid" :planItem="product_pricing" :readOnly="true"/>
          </tbody>
        </table>
        <div v-if="planOption.addons.length > 0" >
          <br>
          <table class="table table-sm">
            <thead>
              <th>Addon</th>
              <th>Addon ID</th>
              <th>Interval</th>
              <th>Amount</th>
              <th>Coupon</th>
              <th>Is Deferred?</th>
            </thead>
            <tbody>
                <PlanListItem v-for="product_pricing in planOption.addons" :key="product_pricing.service_pricing_uuid" :planItem="product_pricing" :readOnly="true"/>
            </tbody>
          </table>
        </div>
      </div>
    </td>
    <td>{{ this.money(planOption.related_plan_pricing.amount) }}</td>
    <td>{{ planOption.related_plan_pricing.coupon_name }}</td>
    <td class="center">
      <input
        type="checkbox"
        class="zb-checkbox me-2"
        :checked="planOption.can_be_ordered_zg"
        @change="patchPlanOptions($event, planOption, 'can_be_ordered_zg')"
      />
    </td>
    <td class="center">
      <input
        type="checkbox"
        class="zb-checkbox me-2"
        :checked="planOption.can_be_downgraded_zg"
        @change="patchPlanOptions($event, planOption, 'can_be_downgraded_zg')"
      />
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { moneyMixin } from "@/components/mixins";
import PlanListItem from "./plan-pricing-list-item";

export default {
  name: 'PlanOptionListItem',
  props: ['planOption'],
  mixins: [moneyMixin],
  components: { PlanListItem },
  data() {
    return {
      showContent: false
    };
  },
  computed: {
    ...mapGetters({
      successAlert: 'getSuccessAlert',
    }),
    arrowClass() {
      return {
        up: this.showContent,
        down: !this.showContent
      };
    }
  },
  methods: {
    ...mapActions("products", ["patchPlanOptionsV2"]),
    toggleContent() {
      this.showContent = !this.showContent;
    },
    patchPlanOptions(event, plan, field) {
      this.patchPlanOptionsV2({
        planUuid: plan.uuid,
        payload: {
          [field]: event.target.checked,
        },
      });
    },
  },
};
</script>

<style scoped>
  .plan-name {
    display: flex;
  }
  .arrow-box {
    margin: 0 8px;
  }
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 1px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .center {
    justify-content: center;
  }
</style>
