<template>
  <div>
    <vue-headful title="Coupons" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Coupons</h1>
          <input v-if="showCreateButton" type="button" class="btn-info btn-sm" @click="couponCreateModal = true" value="Create Coupon" />
          <CouponCreateModal  v-if="couponCreateModal" @close="couponCreateModal = false" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Coupon" v-model="filteredCoupon"/>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredCoupons" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="coupon_uuid">Coupon</sort-link></th>
            <th><sort-link name="display_name">Coupon</sort-link></th>
            <th><sort-link name="amount">Amount</sort-link></th>
            <th>Recurly ID</th>
            <th>Recurly Code</th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <CouponListItem v-for="coupon in sort.values" :key="coupon.coupon_uuid" :coupon="coupon" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="coupons.page" :totalPages="coupons.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import CouponListItem from './coupon-list-item';
import Pagination from '../utils/pagination';
import CouponCreateModal from './coupon-create-modal';
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'CouponList',
  components: {
    CouponListItem,
    Pagination,
    CouponCreateModal
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
      filteredCoupon: undefined,
      couponCreateModal: false,
      showCreateButton: this.hasPermission('write:pricing'),
    };
  },
  computed: {
    coupons: createInstance('coupons', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.filteredCoupon) {
          params.external_coupon_id = this.filteredCoupon;
        }
        return params;
      }
    }),
    filteredCoupons() {
      return this.coupons.items;
    },
    showCreateCoupon() {
      return this.hasPermission('write:pricing');
    }
  },
  methods: {
    clearFilters() {
      this.data = {
        filteredCoupon: undefined
      };
    }
  }
};

</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
