<template>
  <main>
    <header>C-Corp Stock Calculator</header>
    <ZgCard :orientation="'col'">
      <header>
        <div v-if="!isCreationCalculatorOpen" @click="isCreationCalculatorOpen=true; calculatorToEditCreate={}"><i class="fa fa-plus-circle"></i>&nbsp;Create a new Calculator</div>
        <div v-if="isCreationCalculatorOpen" @click="isCreationCalculatorOpen=false">Close Calculator</div>
      </header>
      <transition name="bounce">
        <ServicesCCorpCalculatorCreateEdit v-if="isCreationCalculatorOpen" v-on:close="handleCalculatorClose" :calculator="calculatorToEditCreate"/>
      </transition>
    </ZgCard>
    <zg-card :orientation="'col'">
      <header>List of Calculators</header>
      <table class="table table-sm table-borderless">
        <thead>
        <tr>
          <ZgOrderTH :order.sync="ordering.name">Name</ZgOrderTH>
          <ZgOrderTH :order.sync="ordering.display_name">Display Name</ZgOrderTH>
          <ZgOrderTH :order.sync="ordering.active">Active</ZgOrderTH>
          <ZgOrderTH :order.sync="ordering.product_type">Product Service</ZgOrderTH>
          <ZgOrderTH :order.sync="ordering.jurisdiction_definition">Jursidiction Definition</ZgOrderTH>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
          <CCorpCalculatorListItem v-for="calculator in filteredCCorpStockCalculators"
                                   :key="calculator.uuid" :calculator="calculator"
                                    v-on:view="viewCalculator($event)"
          />
        </tbody>
      </table>
      <Pagination :page.sync="ccorpStockCalculators.page" :totalPages="ccorpStockCalculators.totalPages" v-if="recordsExist" />
    </zg-card>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {createInstance, resource} from 'vuex-pagination'
import ZgCard from '@/components/common/Generics/ZgCard.vue';
import ZgOrderTH from '@/components/common/Generics/ZgOrderTH.vue';
import { hasPermissionMixin } from '@/components/mixins';
import CCorpCalculatorListItem from '@/components/products/services-c-corp-calculator-list-item.vue';
import Pagination from '@/components/utils/pagination.vue';
import ServicesCCorpCalculatorCreateEdit from './ServicesCCorpCalculatorCreateEdit'

export default {
  name: 'ServicesCCorpCalculator',
  components: {
    ServicesCCorpCalculatorCreateEdit,
    ZgCard,
    ZgOrderTH,
    Pagination,
    CCorpCalculatorListItem
  },
  mixins: [hasPermissionMixin],
  data(){
    return {
      ordering: {
        name: null,
        display_name: null,
        active: null,
        jurisdiction_definition: null,
        product_type: null
      },
      isCreationCalculatorOpen: false,
      calculatorToEditCreate: {}
    }
  },
  methods: {
    ...mapActions({
      fetchJurisdictionStates: 'products/fetchJurisdictionStates',
      fetchAllProducts: 'products/fetchAllProducts',
      createCCorpCalculator: 'products/createCCorpCalculator',
      fetchUserEmail: 'users/fetchUserEmail'
    }),
    ...mapGetters({
      getProducts: 'products/getProducts',
      getUserEmail: 'users/getUserEmail',
      getListCCorpStockCalculators: 'products/'
    }),
    openManageTerms() {
      this.isManageTermsModalOpen = true
    },
    viewCalculator(calc) {
      this.isCreationCalculatorOpen = true
      this.calculatorToEditCreate = calc
    },
    handleCalculatorClose() {
      resource('ccorpStockCalculators').refresh();
      this.isCreationCalculatorOpen = false
    }
  },
  computed: {
    ccorpStockCalculators: createInstance('ccorpStockCalculators', {
      page: 1,
      pageSize: 3,
      args() {
        const params = {};
        let paramsOrdering = ''
        for(let [key, value] of Object.entries(this.ordering)) {
          if(this.ordering[key]) {
            const sign = value == 'asc' ? '' : '-';
            const comma = paramsOrdering==''?'':',';
            paramsOrdering = `${paramsOrdering}${comma}${sign}${key}`;
          }
        }
        if (paramsOrdering!='') params.ordering = paramsOrdering;
        return params;
      }
    }),
    filteredCCorpStockCalculators() {
      return this.ccorpStockCalculators.items;
    },
    recordsExist() {
      return this.ccorpStockCalculators.totalPages > 1;
    }
  }
};
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    header {
      font-size: rem(20);
      font-weight: bold;
      padding: 1em 0 1em 0;
    }
    .zg-card {
      margin-bottom: 1em;

      header {
        font-size: rem(18);
        font-weight: bold;
        padding: 0.5em 0 0.5em 0;
        &:hover {
          cursor: pointer
        }
      }
      .form-group {
        padding: 0 0 1em 0;
      }
      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
