<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <h5 class="modal-title">Create new Plan</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group col-md">
            <div class="form-row">
              <label>Plan Product</label>
            </div>
            <div class="form-row">
              <v-select class="w-100" :options="planProductsV2" label="service_product_name_id" :value="planOption.planProduct" v-on:input="planProductSelectedCallback"/>
            </div>
          </div>
          <div class="form-group col-md">
            <div class="form-row">
              <label>Plan Price</label>
            </div>
            <div class="form-row">
              <table class="table table-sm">
                <thead>
                  <th>Plan</th>
                  <th>Price Group</th>
                  <th>Interval</th>
                  <th>Amount</th>
                  <th>Coupon</th>
                  <th>Is Deferred?</th>
                  <th></th>
                </thead>
                <tbody v-for="servicePricing in planOption.pricing" :key="servicePricing.service_pricing_uuid">
                  <tr>
                    <td>{{ servicePricing.service }}</td>
                    <td>{{ servicePricing.price_group }}</td>
                    <td>{{ servicePricing.interval }}</td>
                    <td>{{ servicePricing.amount }}</td>
                    <td>{{ servicePricing.coupon_name }}</td>
                    <td>{{ servicePricing.defer_until_formation_complete }}</td>
                    <td><i class="fas fa-pencil-alt text-primary" v-on:click="servicePricing.displayPrices = !servicePricing.displayPrices"></i></td>
                  </tr>
                  <template v-if="servicePricing.displayPrices">
                    <tr v-for="pricingOption in pricingV2ByProduct[servicePricing.service].pricing" :key="pricingOption.service_pricing_uuid" class="price-option">
                      <td>{{ pricingOption.service }}</td>
                      <td>{{ pricingOption.price_group }}</td>
                      <td>{{ pricingOption.interval }}</td>
                      <td>{{ pricingOption.amount }}</td>
                      <td>{{ pricingOption.coupon_name }}</td>
                      <td>{{ pricingOption.defer_until_formation_complete }}</td>
                      <td><input type="radio" v-model="picked[pricingOption.service]" :value="pricingOption" v-on:change="priceSelectedCallback(servicePricing, 'plan')"></td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group col-md">
            <div class="form-row">
              <label>Plan Contents</label>
            </div>
            <div class="form-row">
              <table class="table table-sm">
                <thead>
                  <th>Product</th>
                  <th>Price Group</th>
                  <th>Interval</th>
                  <th>Amount</th>
                  <th>Coupon</th>
                  <th>Is Deferred?</th>
                  <th></th>
                </thead>
                <tbody v-for="servicePricing in planOption.contents" :key="servicePricing.service_pricing_uuid">
                  <tr>
                    <td>{{ servicePricing.service }} <i v-if="servicePricing.isBundle" class="fas fa-box-open text-dark"></i></td>
                    <td>{{ servicePricing.price_group }}</td>
                    <td>{{ servicePricing.interval }}</td>
                    <td>{{ servicePricing.amount }}</td>
                    <td>{{ servicePricing.coupon_name }}</td>
                    <td>{{ servicePricing.defer_until_formation_complete }}</td>
                    <td><i class="fas fa-pencil-alt text-primary" v-on:click="servicePricing.displayPrices = !servicePricing.displayPrices"></i></td>
                  </tr>
                  <!-- Single Product Prices -->
                  <template v-if="servicePricing.displayPrices && !servicePricing.isBundle">
                    <tr v-for="pricingOption in pricingV2ByProduct[servicePricing.service].pricing" :key="pricingOption.service_pricing_uuid" class="price-option">
                      <td>{{ pricingOption.service }} </td>
                      <td>{{ pricingOption.price_group }}</td>
                      <td>{{ pricingOption.interval }}</td>
                      <td>{{ pricingOption.amount }}</td>
                      <td>{{ pricingOption.coupon_name }}</td>
                      <td>{{ pricingOption.defer_until_formation_complete }}</td>
                      <td><input type="radio" v-model="picked[servicePricing.service]" :value="pricingOption" v-on:change="priceSelectedCallback(servicePricing, 'product')"></td>
                    </tr>
                  </template>
                  <!-- Bundle PlanOptions in Contents -->
                  <template v-if="servicePricing.displayPrices && servicePricing.isBundle">
                    <tr v-if="Object.keys(planOptionsV2ByPlan).indexOf(servicePricing.service) === -1" class="price-option">
                      <td colspan="7">
                        There are 0 Plan Options for this product. Create one first.
                      </td>
                    </tr>
                    <tr v-for="planOptionOption in planOptionsV2ByPlan[servicePricing.service]" :key="planOptionOption.uuid" class="price-option">
                      <td colspan="6">
                        <div>
                        <table>
                          <tbody>
                            <PlanOptionListItem :planOption="planOptionOption" />
                          </tbody>
                        </table>
                        </div>
                      </td>
                      <td><input type="radio" v-model="picked[servicePricing.service]" :value="planOptionOption.related_plan_pricing" v-on:change="priceSelectedCallback(servicePricing, 'product', planOptionOption.uuid)"></td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group col-md">
            <div class="form-row">
              <label>Plan Addons</label>
            </div>
            <div class="form-row">
              <v-select multiple class="w-100" :options="addonsSelector.selectable" label='addons' :value="addonsSelector.selected" v-on:input="addonsSelectedCallback"/>
            </div>
            <div class="form-row">
              <table class="table table-sm">
                <thead>
                  <th>Addon</th>
                  <th>Price Group</th>
                  <th>Interval</th>
                  <th>Amount</th>
                  <th>Coupon</th>
                  <th>Is Deferred?</th>
                  <th></th>
                </thead>
                <tbody v-for="servicePricing in planOption.addons" :key="servicePricing.service_pricing_uuid">
                  <tr>
                    <td>{{ servicePricing.service }} <i v-if="servicePricing.isBundle" class="fas fa-box-open text-dark"></i></td>
                    <td>{{ servicePricing.price_group }}</td>
                    <td>{{ servicePricing.interval }}</td>
                    <td>{{ servicePricing.amount }}</td>
                    <td>{{ servicePricing.coupon_name }}</td>
                    <td>{{ servicePricing.defer_until_formation_complete }}</td>
                    <td><i class="fas fa-pencil-alt text-primary" v-on:click="servicePricing.displayPrices = !servicePricing.displayPrices"></i></td>
                  </tr>
                  <!-- Single Addon Prices -->
                  <template v-if="servicePricing.displayPrices && !servicePricing.isBundle">
                    <tr v-for="pricingOption in pricingV2ByProduct[servicePricing.service].pricing" :key="pricingOption.service_pricing_uuid" class="price-option">
                      <td>{{ pricingOption.service }}</td>
                      <td>{{ pricingOption.price_group }}</td>
                      <td>{{ pricingOption.interval }}</td>
                      <td>{{ pricingOption.amount }}</td>
                      <td>{{ pricingOption.coupon_name }}</td>
                      <td>{{ pricingOption.defer_until_formation_complete }}</td>
                      <td><input type="radio" v-model="picked[servicePricing.service]" :value="pricingOption" v-on:change="priceSelectedCallback(servicePricing, 'addon')"></td>
                    </tr>
                  </template>
                  <!-- Bundle PlanOptions in Addons -->
                  <template v-if="servicePricing.displayPrices && servicePricing.isBundle">
                    <tr v-if="Object.keys(planOptionsV2ByPlan).indexOf(servicePricing.service) === -1" class="price-option">
                      <td colspan="7">
                        There are 0 Plan Options for this addon. Create one first.
                      </td>
                    </tr>
                    <tr v-for="planOptionOption in planOptionsV2ByPlan[servicePricing.service]" :key="planOptionOption.uuid" class="price-option">
                      <td colspan="6">
                        <div>
                        <table>
                          <tbody>
                            <PlanOptionListItem :planOption="planOptionOption" />
                          </tbody>
                        </table>
                        </div>
                      </td>
                      <td><input type="radio" v-model="picked[servicePricing.service]" :value="planOptionOption.related_plan_pricing" v-on:change="priceSelectedCallback(servicePricing, 'addon', planOptionOption.uuid)"></td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="alert alert-info creation-tips" role="alert" >
              <ul>
                <li><i class="fas fa-pencil-alt text-primary"></i> Show/Hide price options for each item using the pencil next to it.</li>
                <li><i class="fas fa-box-open text-dark"></i> Bundle items are marked with a box next to them.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" :disabled="!isFormValid" @click="handleCreatePlanOption">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { resource } from 'vuex-pagination';
import cloneDeep from 'lodash/cloneDeep';
import PlanOptionListItem from './plan-option-list-item';

export default {
  name: 'PlanOptionCreateModal',
  components: { PlanOptionListItem },
  data() {
    return {
      itemDefaults: {
        price_group: null,
        interval: null,
        amount: null,
        coupon_name: null,
        defer_until_formation_complete: null,
        displayPrices: false
      },
      planOption: {
        planProduct: null,
        pricing: null,
        contents: null,
        addons: null
      },
      addonsSelector: {
        all: [],
        selectable: [],
        selected: []
      },
      picked: {}
    };
  },
  computed: {
    ...mapGetters('products', ['planProductsV2', 'pricingV2ByProduct', 'planOptionsV2ByPlan']),
    isFormValid() {
      const planPriceIsPicked = this.allPricingArePicked(this.planOption.pricing);
      const contentsPricingArePicked = this.allPricingArePicked(this.planOption.contents);
      const addonsPricingArePicked = this.allPricingArePicked(this.planOption.addons);
      return planPriceIsPicked && contentsPricingArePicked && addonsPricingArePicked;
    },
    planProduct() {
      if (this.planOption.planProduct) {
        return this.planOption.planProduct;
      }
      return null;
    },
    planContents() {
      if (this.planOption.planProduct) {
        return this.planProduct.contents.map((product) => product.service);
      }
      return [];
    },
    bundleProductsPricingV2ByProduct() {
      return Object.fromEntries(Object.entries(this.pricingV2ByProduct).filter(([ , productPricing]) => productPricing.isBundle));
    },
    bundleProducts() {
      return Object.keys(this.bundleProductsPricingV2ByProduct);
    }
  },
  methods: {
    beforeCreate() {
      this.clearFields();
    },
    clearFields() {
      this.planOption = {
        planProduct: null,
        pricing: null,
        contents: null,
        addons: null
      };
      this.addonsSelector = {
        all: [],
        selectable: [],
        selected: []
      };
      this.picked = {};
    },
    allPricingArePicked(pricing) {
      if (pricing || pricing === []) {
        const priceIsPicked = (price) => Boolean(price.service_pricing_uuid);
        return pricing.every(priceIsPicked);
      }
      return false;
    },
    handleCreatePlanOption() {
      const planOption = {
        plan_pricing: this.planOption.pricing[0].service_pricing_uuid,
        contents_pricing: this.planOption.contents,
        addons_pricing: this.planOption.addons
      };
      this.$store.dispatch('products/createPlanOption', planOption)
        .then(() => {
          resource('planOptions').refresh();
          this.$emit('close');
        });
    },
    isBundle(productId) {
      return !(this.bundleProducts.indexOf(productId) === -1);
    },
    buildTableRow(productIds) {
      return productIds.map((productId) => ({ ...this.itemDefaults, service: productId, isBundle: this.isBundle(productId) }));
    },
    getContentProductIds(planProductId) {
      const bundles = this.planProductsV2.filter((planProduct) => planProduct.service_product_name_id === planProductId);
      const defaultBundle = { contents: [] };
      const bundle = bundles.length > 0 ? bundles[0] : defaultBundle;
      return bundle.contents.map((product) => product.service);
    },
    planProductSelectedCallback(selectedPlanProduct) {
      this.planOption.planProduct = selectedPlanProduct;

      if (!selectedPlanProduct) {
        this.clearFields();
      } else {
        // Sort contents: put bundles at the end
        let contents = this.planContents;
        contents = contents.filter((p) => !this.isBundle(p)).concat(contents.filter((p) => this.isBundle(p)));

        // Populate addonsSelector
        const bundlesInContents = this.planProduct.contents.filter((product) => product.category === 'plan').map((product) => product.service);
        const nestedContents = bundlesInContents.map((bundleProductId) => this.getContentProductIds(bundleProductId)).flat();
        const allContents = contents.concat(nestedContents);
        this.addonsSelector.all = Object.keys(this.pricingV2ByProduct).filter((productId) => allContents.indexOf(productId) === -1 && productId.indexOf('plan') === -1);
        this.addonsSelector.selectable = this.addonsSelector.all;

        // Populate plan option contents and set addons as empty list
        this.planOption.pricing = this.buildTableRow([this.planProduct.service_product_name_id]);
        this.planOption.contents = this.buildTableRow(contents);
        this.planOption.addons = [];

        // Create Object to save picked prices for the plan and its contents
        const all = [this.planProduct.service_product_name_id, ...this.planContents];
        this.picked = Object.fromEntries(all.map((productId) => [productId, null]));
      }
    },
    priceSelectedCallback(servicePricing, type, servicePlanOption = null) {
      // Save picked price data of the plan, product or addon in this.planOption
      switch (type) {
        case 'plan': {
          this.planOption.pricing = [{ ...this.picked[servicePricing.service], displayPrices: false }];
          break;
        }
        case 'product': {
          const productIndex = this.planOption.contents.map((c) => c.service).indexOf(servicePricing.service);
          const contents = cloneDeep(this.planOption.contents);
          Object.assign(contents[productIndex], this.picked[servicePricing.service]);
          contents[productIndex].displayPrices = false;
          contents[productIndex].plan_option_uuid = servicePlanOption;
          this.planOption.contents = contents;
          break;
        }
        case 'addon': {
          const addonIndex = this.planOption.addons.map((a) => a.service).indexOf(servicePricing.service);
          const addons = cloneDeep(this.planOption.addons);
          Object.assign(addons[addonIndex], this.picked[servicePricing.service]);
          addons[addonIndex].displayPrices = false;
          addons[addonIndex].plan_option_uuid = servicePlanOption;
          this.planOption.addons = addons;
          break;
        }
      }
    },
    getProductsPricing(productIds) {
      // Get array of ServicePricing objects whose service name is in productIds
      return Object.entries(this.pricingV2ByProduct).filter(([productId, ]) => productIds.indexOf(productId) !== -1).map((p) => p[1]);
    },
    addonsSelectedCallback(selectedAddonsProductIds) {
      this.addonsSelector.selected = selectedAddonsProductIds;
      if (!selectedAddonsProductIds) {
        // Remove all addons from planOption
        this.addonsSelector.selectable = this.addonsSelector.all;
        this.planOption.addons = [];
      } else {
        // Update addonsSelector
        const selectedAddonsPricingOptions = this.getProductsPricing(selectedAddonsProductIds);
        const bundlesInAddons = selectedAddonsPricingOptions.filter((pricing) => pricing.isBundle || pricing.isPlan).map((pricing) => pricing.pricing[0].service);
        const nestedAddons = bundlesInAddons.map((bundleProductId) => this.getContentProductIds(bundleProductId)).flat();
        const allSelectedAddons = new Set(this.addonsSelector.selected.concat(nestedAddons));
        this.addonsSelector.selectable = this.addonsSelector.all.filter((productId) => !allSelectedAddons.has(productId));

        // Local variable addons to later replace this.planOption.addons
        let { addons } = this.planOption;

        // If an addon is removed from the selector, remove it from this.picked & this.planOption.addons
        const nonAddonProducts = new Set([this.planProduct.service_product_name_id, ...this.planContents]);
        const deletedProducts = Object.keys(this.picked).filter((productId) => !nonAddonProducts.has(productId) && selectedAddonsProductIds.indexOf(productId) === -1);
        deletedProducts.map((productId) => { delete this.picked[productId]; });
        addons = addons.filter((pricing) => deletedProducts.indexOf(pricing.service) === -1);

        // If an addon is added to the selector, add new selected addons to this.planOption.addons & this.picked
        const addonsDisplayed = new Set(addons.map((addon) => addon.service));
        const addonsNotDisplayed = selectedAddonsProductIds.filter((productId) => !addonsDisplayed.has(productId));
        addons = addons.concat(this.buildTableRow(addonsNotDisplayed));
        Object.assign(this.picked, Object.fromEntries(addonsNotDisplayed.map((productId) => [productId, null])));

        // Sort addons: put bundles at the end
        this.planOption.addons = addons.filter((addon) => !this.isBundle(addon.service)).concat(addons.filter((addon) => this.isBundle(addon.service)));
      }
    }
  },
  mounted() {
    this.$store.dispatch('products/fetchPlanProductsV2');
    this.$store.dispatch('products/fetchServicePricingV2');
    this.$store.dispatch('products/fetchPlanOptionsV2');
  }
};
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
  }
  table i {
    text-align: center;
  }
  .price-option {
    background: #eee;
  }
  .creation-tips ul {
    padding: 0;
    list-style-type: none;
    -webkit-padding-start: 0;
  }
</style>
