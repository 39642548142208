<template>
  <div class="modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage fields for {{ jurisdiction_name }}</h5>
          <ZgButton class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')" type="button"></ZgButton>
        </div>
        <div class="modal-body">
          <div class="content-fluid">
            <div class="row">
              <div class="col-2">Term</div>
              <div class="col-2">Display name</div>
              <div class="col-3">Description</div>
              <div class="col-2">Field type</div>
              <div class="col-2">Is required?</div>
              <div class="col-1"></div>
            </div>

            <div v-for="field in existing_fields" :key="field.uuid" class="row mb-4 form-group">
              <div class="col-2">
                <select class="form-control form-control-sm" v-model="field.term">
                  <option v-for="term in getCCorpTerms" :key="term.uuid" v-bind:value="term.term_name">{{term.display_name}}</option>
                </select>
              </div>
              <div class="col-2"><input class="form-control form-control-sm" type="text" v-model="field.display_name" /></div>
              <div class="col-3"><input class="form-control form-control-sm" type="text" v-model="field.field_description" /></div>
              <div class="col-2">
                <select class="form-control form-control-sm" v-model="field.field_type">
                  <option value="string">String</option>
                  <option value="number">Number</option>
                  <option value="boolean">Boolean</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="col-2"><input class="form-check-input" v-model="field.is_required" type="checkbox" /></div>
              <div class="col-1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="deleteField(field)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>

            <div v-for="(field, index) in new_fields" :key="index" @change="handleFieldChange(index)" class="row mb-4 form-group">
              <div class="col-2">
                <select class="form-control form-control-sm" v-model="field.term_uuid">
                  <option value="" selected>Select a term</option>
                  <option v-for="term in getCCorpTerms" :key="term.uuid" v-bind:value="term.uuid">{{term.display_name}}</option>
                </select>
              </div>
              <div class="col-2"><input class="form-control form-control-sm" type="text" v-model="field.display_name" /></div>
              <div class="col-3"><input class="form-control form-control-sm" type="text" v-model="field.field_description" /></div>
              <div class="col-2">
                <select class="form-control form-control-sm" v-model="field.field_type">
                  <option value="string" selected="selected">String</option>
                  <option value="number">Number</option>
                  <option value="boolean">Boolean</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="col-2"><input class="form-check-input" v-model="field.is_required" type="checkbox" /></div>
              <div class="col-1" v-if="new_fields.length > 1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="removeNewField(index)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn btn-success action" @click="handleFieldsSave">Save</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ZgButton from '../common/Generics/ZgButton.vue'

export default {
  name: 'ServicesCCorpManageFields',

  props: ['jurisdiction', 'jurisdiction_name'],
  data(){
    return {
      existing_fields: [],
      new_fields: [
        {
          term_uuid: '',
          display_name: '',
          field_description: '',
          field_type: '',
          is_required: false
        }
      ]
    }
  },
  components: {
    ZgButton
  },
  methods: {
    ...mapActions({
      fetchJurisdictionStates: 'products/fetchJurisdictionStates',
      addCCorpField: 'products/addCCorpField',
      updateCCorpField: 'products/updateCCorpField',
      deleteCCorpField: 'products/deleteCCorpField',
      fetchCCorpFields: 'products/fetchCCorpFields',
      fetchCCorpTerms: 'products/fetchCCorpTerms'
    }),
    addField(jurisdiction, field_uuid, term_uuid, display_name, field_description, field_type, is_required) {
      this.existing_fields.push({
          jurisdiction: jurisdiction,
          field_uuid: field_uuid,
          term: term_uuid,
          display_name: display_name,
          field_description: field_description,
          field_type: field_type,
          is_required: is_required
      })
    },
    addNewField(){
      this.new_fields.push({
          term_uuid: '',
          display_name: '',
          field_description: '',
          field_type: '',
          is_required: false
      })
    },
    removeNewField(index){
      this.new_fields.splice(index, 1);
    },
    deleteField(field) {
      this.deleteCCorpField({field_uuid: field.field_uuid}).then(() => {
        this.fetchJurisdictionStates()
      })
      this.$emit('close')
    },
    handleFieldChange(index) {
      if (this.new_fields.length > 1 && !this.new_fields[index].display_name && !this.new_fields[index].term_uuid && !this.new_fields[index].field_type)
        this.removeNewField(index)
      
      if (this.new_fields[this.new_fields.length - 1].display_name && this.new_fields[this.new_fields.length - 1].term_uuid && this.new_fields[this.new_fields.length - 1].field_type)
        this.addNewField()
    },
    handleFieldsSave() {

      for (let index = 0; index < this.existing_fields.length; index++) {
        const element = this.existing_fields[index];
        if (this.existing_fields[index].display_name && this.existing_fields[index].term && this.existing_fields[index].field_type) {
          this.updateCCorpField({ element: element, jurisdiction: this.jurisdiction, term: this.searchTerm(element.term) }).then(() => {
        this.fetchJurisdictionStates()
      })
        }
      }

      for (let index = 0; index < this.new_fields.length; index++) {
        const element = this.new_fields[index];
        if (this.new_fields[index].display_name && this.new_fields[index].term_uuid && this.new_fields[index].field_type) {
          this.addCCorpField({ element: element, jurisdiction: this.jurisdiction }).then(() => {
        this.fetchJurisdictionStates()
      })
        }
      }
      this.$emit('close')
    },
    searchTerm(term_name){
      return this.$store.state.products.ccorpTerms.filter((term) => term.term_name === term_name)[0]
    }
  },
  computed: {
    getCCorpFields(){
      return this.$store.state.products.ccorpFields
    },
    getCCorpTerms(){
      return this.$store.state.products.ccorpTerms.filter((term) => term.term_type === 'field')
    }
  },
  mounted() {
    this.fetchCCorpTerms()
    this.fetchCCorpFields({ jurisdiction: this.jurisdiction }).then(() => {
      for (let index = 0; index < this.getCCorpFields.length; index++) {
        const element = this.getCCorpFields[index];
        this.addField(element.jurisdiction, element.uuid, element.term, element.display_name, element.field_description, element.field_type, element.is_required)
      }
    })
  }
};
</script>
