<template>
  <li>{{ serviceProduct.product_name }}</li>
</template>

<script>
export default {
  name: 'ServiceProductPlanListItemProduct',
  props: ['serviceProduct']
};
</script>

<style lang="scss" scoped>
  .table td {
      vertical-align: top;
      border-top: none;
      border-bottom: 1px solid #dee2e6;
  }
</style>
