<template>
  <div class="modal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage fees for {{ jurisdiction_name }}</h5>
          <ZgButton class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')" type="button"></ZgButton>
        </div>
        <div class="modal-body">
          <div class="content-fluid">
            <div class="row">
              <div class="col-3">Term</div>
              <div class="col-3">Fee name</div>
              <div class="col-5">Fee description</div>
              <div class="col-1"></div>
            </div>

            <div v-for="fee in existing_fees" :key="fee.uuid" class="row mb-4 form-group">
              <div class="col-3">
                <select class="form-control form-control-sm" v-model="fee.term">
                  <option v-for="term in getCCorpTerms" :key="term.uuid" v-bind:value="term.term_name">{{term.display_name}}</option>
                </select>
              </div>
              <div class="col-3"><input class="form-control form-control-sm" type="text" v-model="fee.fee_name" /></div>
              <div class="col-5"><input class="form-control form-control-sm" type="text" v-model="fee.fee_description" /></div>
              <div class="col-1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="deleteFee(fee)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>

            <div v-for="(fee, index) in new_fees" :key="index" @change="handleFeeChange(index)" class="row mb-4 form-group">
              <div class="col-3">
                <select class="form-control form-control-sm" v-model="fee.term_uuid">
                  <option value="" selected>Select a term</option>
                  <option v-for="term in getCCorpTerms" :key="term.uuid" v-bind:value="term.uuid">{{term.display_name}}</option>
                </select>
              </div>
              <div class="col-3"><input class="form-control form-control-sm" type="text" v-model="fee.fee_name" /></div>
              <div class="col-5"><input class="form-control form-control-sm" type="text" v-model="fee.fee_description" /></div>
              <div class="col-1" v-if="new_fees.length > 1">
                <ZgButton class="btn btn-danger zg-text-sm" data-bs-dismiss="modal" aria-label="Close" @click="removeNewFee(index)" type="button">
                  <template v-slot:title><i class="fa fa-trash"></i></template>
                </ZgButton>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn btn-success action" @click="handleFeesSave">Save</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import ZgButton from '../common/Generics/ZgButton.vue'

export default {
  name: 'ServicesCCorpManageFees',

  props: ['jurisdiction', 'jurisdiction_name'],
  data(){
    return {
      existing_fees: [],
      new_fees: [
        {
          term_uuid: '',
          fee_name: '',
          fee_description: ''
        }
      ]
    }
  },
  components: {
    ZgButton,
  },
  methods: {
    ...mapActions({
      fetchJurisdictionStates: 'products/fetchJurisdictionStates',
      addCCorpFee: 'products/addCCorpFee',
      updateCCorpFee: 'products/updateCCorpFee',
      deleteCCorpFee: 'products/deleteCCorpFee',
      fetchCCorpFees: 'products/fetchCCorpFees',
      fetchCCorpTerms: 'products/fetchCCorpTerms'
    }),
    addFee(jurisdiction, fee_uuid, term_uuid, fee_name, fee_description) {
      this.existing_fees.push({
          jurisdiction: jurisdiction,
          fee_uuid: fee_uuid,
          term: term_uuid,
          fee_name: fee_name,
          fee_description: fee_description
      })
    },
    addNewFee(){
      this.new_fees.push({
          term_uuid: '',
          fee_name: '',
          fee_description: ''
      })
    },
    removeNewFee(index){
      this.new_fees.splice(index, 1);
    },
    deleteFee(fee) {
      this.deleteCCorpFee({fee_uuid: fee.fee_uuid}).then(() => {
        this.fetchJurisdictionStates()
      })
      this.$emit('close')
    },
    handleFeeChange(index) {
      if (this.new_fees.length > 1 && !this.new_fees[index].fee_name && !this.new_fees[index].term_uuid)
        this.removeNewFee(index)
      
      if (this.new_fees[this.new_fees.length - 1].fee_name && this.new_fees[this.new_fees.length - 1].term_uuid)
        this.addNewFee()
    },
    handleFeesSave(){
      for (let index = 0; index < this.existing_fees.length; index++) {
        const element = this.existing_fees[index];

        if (this.existing_fees[index].fee_name && this.existing_fees[index].term) {
          this.updateCCorpFee({element: element, jurisdiction: this.jurisdiction, term: this.searchTerm(element.term)}).then(() => {
            this.fetchJurisdictionStates()
          })
        }
      }
      for (let index = 0; index < this.new_fees.length; index++) {
        const element = this.new_fees[index];
        if (this.new_fees[index].fee_name && this.new_fees[index].term_uuid) {
          this.addCCorpFee({element: element, jurisdiction: this.jurisdiction}).then(() => {
            this.fetchJurisdictionStates()
          })
        }
      }
      this.$emit('close')
    },
    searchTerm(term_name){
      return this.$store.state.products.ccorpTerms.filter((term) => term.term_name === term_name)[0]
    }
  },
  computed: {
    getCCorpFees(){
      return this.$store.state.products.ccorpFees
    },
    getCCorpTerms(){
      return this.$store.state.products.ccorpTerms.filter((term) => term.term_type === 'fee')
    }
  },
  mounted() {
    this.fetchCCorpTerms()
    this.fetchCCorpFees(this.jurisdiction).then(() => {
      for (let index = 0; index < this.getCCorpFees.length; index++) {
        const element = this.getCCorpFees[index];
        this.addFee(element.jurisdiction, element.uuid, element.term, element.fee_name, element.fee_description)
      }
    })
  }
};
</script>
