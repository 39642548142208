<template>
  <tr>
    <td>{{ affiliatePlanOption.related_plan_option.related_plan_pricing.service_pricing_uuid }}</td>
    <td>
      <div class="plan-name" >
        <div class="arrow-box" v-on:click="toggleContent"><i class="arrow" v-bind:class="arrowClass" ></i></div>
        {{ affiliatePlanOption.related_plan_option.related_plan_pricing.service }}
      </div>
      <div v-if="showContent">
        <br>
        <table class="table table-sm">
          <thead>
            <th>Product</th>
            <th>Product ID</th>
            <th>Interval</th>
            <th>Amount</th>
            <th>Attributed price</th>
            <th>Coupon</th>
            <th>Is Deferred?</th>
          </thead>
          <tbody>
            <AffiliatePlanListItem
              v-for="product_pricing in affiliatePlanOption.related_plan_option.plan_products"
              :key="product_pricing.service_pricing_uuid"
              :planItem="product_pricing"
              :readOnly="true"
            />
          </tbody>
        </table>
        <div v-if="affiliatePlanOption.related_plan_option.plan_addons.length > 0" >
          <br>
          <table class="table table-sm">
            <thead>
              <th>Addon</th>
              <th>Addon ID</th>
              <th>Interval</th>
              <th>Amount</th>
              <th>Coupon</th>
              <th>Is Deferred?</th>
            </thead>
            <tbody>
              <AffiliatePlanListItem
                v-for="addon_pricing in affiliatePlanOption.related_plan_option.plan_addons"
                :key="addon_pricing.service_pricing_uuid"
                :planItem="addon_pricing"
                :readOnly="true"
              />
            </tbody>
          </table>
        </div>
      </div>
    </td>
    <td>{{ this.money(affiliatePlanOption.related_plan_option.related_plan_pricing.amount) }}</td>
    <td v-if="affiliatePlanOption.related_plan_option.related_plan_pricing.coupon">
      {{ this.money(affiliatePlanOption.related_plan_option.related_plan_pricing.coupon.amount) }}
    </td>
    <td v-else></td>
    <td>
      <zg-button
        class="zg-btn-default zg-text-sm p-1"
        @click="serviceProductsListModal = true"
      >
        <template v-slot:title>Product Attributes</template>
      </zg-button>
      <ServiceProductsListItemModal
        v-if="serviceProductsListModal"
        @close="serviceProductsListModal = false"
        :product="affiliatePlanOption.related_plan_option.related_plan_pricing.service_product"
      />
    </td>
  </tr>
</template>

<script>
import { moneyMixin } from "@/components/mixins";
import AffiliatePlanListItem from "./affiliate-plan-pricing-list-item";
import ServiceProductsListItemModal from "./service-products-list-item-modal";
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'AvailablePlanOptionListItem',
  props: ['affiliatePlanOption'],
  mixins: [moneyMixin],
  components: { AffiliatePlanListItem, ServiceProductsListItemModal, ZgButton },
  data() {
    return {
      showContent: false,
      serviceProductsListModal: false
    };
  },
  computed: {
    arrowClass() {
      return {
        up: this.showContent,
        down: !this.showContent
      };
    }
  },
  methods: {
    toggleContent() {
      this.showContent = !this.showContent;
    }
  }
};
</script>

<style lang="scss" scoped>
  .plan-name {
    display: flex;
  }
  .arrow-box {
    margin: 0 rem(8px);
  }
  .arrow {
    border: solid black;
    border-width: 0 rem(1px) rem(1px) 0;
    display: inline-block;
    padding: rem(3px);
    margin-bottom: rem(1px);
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
</style>
