<template>
  <tr>
    <td v-if="showAffiliateName">{{ affiliatePricing.related_affiliate.name }}</td>
    <td>{{ affiliatePricing.related_service_pricing.service }}</td>
    <td>{{ affiliatePricing.related_service_pricing.interval }}</td>
    <td>{{ this.money(affiliatePricing.related_service_pricing.amount) }}</td>
    <td>{{ affiliatePricing.related_service_pricing.defer_until_formation_complete }}</td>
    <td>
      <zg-button class="zg-btn-default zg-btn-default zg-text-sm p-1" @click="serviceProductsListModal = true">
        <template v-slot:title>Product Attributes</template>
      </zg-button>
      <ServiceProductsListItemModal  v-if="serviceProductsListModal" @close="serviceProductsListModal = false"  :product="affiliatePricing.related_service_pricing.service_product" />
    </td>
  </tr>
</template>

<script>
import { moneyMixin } from '@/components/mixins';
import ServiceProductsListItemModal from "@/components/products/service-products-list-item-modal";
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'AffiliateRelatedPricingListItem',
  props: {
    affiliatePricing: {},
    showAffiliateName: {
      default: true
    },
  },
  mixins: [moneyMixin],
  components: { ServiceProductsListItemModal, ZgButton },
  data(){
    return {
      serviceProductsListModal: false,
    };
  },
};
</script>
