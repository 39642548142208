<template>
  <div>
    <zg-card-column>
      <template v-slot:content>
        <div class="container">
          <div class="row justify-content-between mb-2">
            <div class="col-auto mb-2">
              <h1 v-if="filteredAffiliate == undefined">Affiliate Pricing</h1>
              <h1 v-else>Affiliate Pricing for '{{filteredAffiliate}}'</h1>
            </div>
          </div>
          <sorted-table :values="filteredPricing" class="table table-sm">
            <thead>
              <tr>
                <th><sort-link name="related_service_pricing.service">Service Name</sort-link></th>
                <th><sort-link name="related_service_pricing.interval">Interval</sort-link></th>
                <th><sort-link name="related_service_pricing.amount">Amount</sort-link></th>
                <th><sort-link name="related_service_pricing.defer_until_formation_complete">Is Deferred?</sort-link></th>
              </tr>
            </thead>
            <tbody slot="body" slot-scope="sort">
              <AffiliateRelatedPricingListItem v-bind:show-affiliate-name=false v-for="affiliatePricing in sort.values" :key="affiliatePricing.uuid" :affiliatePricing="affiliatePricing" />
            </tbody>
          </sorted-table>
          <Pagination :page.sync="affiliatePricing.page" :totalPages="affiliatePricing.totalPages" />
        </div>
      </template>
    </zg-card-column>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import get from 'lodash/get';
import Vue from 'vue';
import Pagination from '../utils/pagination';
import AffiliateRelatedPricingListItem from './affiliate-related-pricing-list-item';
import { hasPermissionMixin } from '../mixins';
import ZgCardColumn from "@/components/common/Generics/ZgCardColumn";

// Enable sorting by nested attributes
Vue.prototype.$_ = {
  get
};

export default {
  name: 'AffiliateRelatedPricingList',
  props: ['filteredAffiliate'],
  components: {
    AffiliateRelatedPricingListItem,
    Pagination,
    ZgCardColumn
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
    };
  },
  computed: {
    affiliatePricing: createInstance('affiliatePricingV3', {
      page: 1,
      pageSize: 10,
      args() {
        const params = {};
        if (this.filteredAffiliate) {
          params.related_affiliate_name = this.filteredAffiliate;
        }
        return params;
      }
    }),
    filteredPricing() {
      return this.affiliatePricing.items;
    },
  }
};
</script>
