<template>
  <tr>
    <td v-if="showAffiliateName">{{ affiliatePlanOption.related_affiliate.name }}</td>
    <td>{{ affiliatePlanOption.uuid }}</td>
    <td>{{ affiliatePlanOption.related_plan_option.related_plan_pricing.service_pricing_uuid }}</td>
    <td>
      <div class="plan-name" >
        <div class="arrow-box" v-on:click="toggleContent"><i class="arrow" v-bind:class="arrowClass" ></i></div>
        {{ affiliatePlanOption.related_plan_option.related_plan_pricing.service }}
      </div>
      <div v-if="showContent">
        <br>
        <table class="table table-sm">
          <thead>
            <th>Product</th>
            <th>Product ID</th>
            <th>Product Service Pricing UUID</th>
            <th>Interval</th>
            <th>Amount</th>
            <th>Attributed price</th>
            <th>Coupon</th>
            <th>Is Deferred?</th>
          </thead>
            <tbody>
                <AffiliatePlanListItem v-for="product_pricing in affiliatePlanOption.related_plan_option.plan_products" :key="product_pricing.service_pricing_uuid" :planItem="product_pricing" :readOnly="true"/>
            </tbody>
        </table>
        <div v-if="affiliatePlanOption.related_plan_option.addons.length > 0" >
          <br>
          <table class="table table-sm">
            <thead>
              <th>Addon</th>
              <th>Addon ID</th>
              <th>Addon Service Pricing UUID</th>
              <th>Interval</th>
              <th>Amount</th>
              <th>Coupon</th>
              <th>Is Deferred?</th>
            </thead>
            <tbody>
                <AffiliatePlanListItem v-for="product_pricing in affiliatePlanOption.related_plan_option.plan_addons" :key="product_pricing.service_pricing_uuid" :planItem="product_pricing" :readOnly="true"/>
            </tbody>
          </table>
        </div>
      </div>
    </td>
    <td>{{ this.money(affiliatePlanOption.related_plan_option.related_plan_pricing.amount) }}</td>
    <td v-if="affiliatePlanOption.related_plan_option.related_plan_pricing.coupon">{{ this.money(affiliatePlanOption.related_plan_option.related_plan_pricing.coupon.amount) }}</td>
    <td v-else></td>
    <td v-if="hasPermission('write:affiliates')">
        <ZgButton class="zg-btn-danger zg-btn-sm" @click="showDeactivateModal = true">
          <template v-slot:title>Deactivate</template>
        </ZgButton>
        <AffiliatePlanOptionDeactivateModal v-if="showDeactivateModal"
                                          :affiliatePlanOptionUUID="affiliatePlanOption.uuid"
                                          @showDeactivateModal="showDeactivateModal = false"
                                          @affiliatePlanDeactivated="handleAffiliatePlanOptionDeactivate"
                                          />
    </td>

  </tr>
</template>

<script>
import { resource } from 'vuex-pagination';
import { moneyMixin, hasPermissionMixin } from '@/components/mixins';
import ZgButton from "@/components/common/Generics/ZgButton";
import AffiliatePlanListItem from './affiliate-plan-pricing-list-item';
import AffiliatePlanOptionDeactivateModal from './affiliate-plan-option-deactivate-modal';

export default {
  name: 'AffiliatePlanOptionListItem',
  props: {
    affiliatePlanOption:{},
    showAffiliateName: {
      default: true
    }
  },
  mixins: [moneyMixin, hasPermissionMixin],
  components: { AffiliatePlanListItem, AffiliatePlanOptionDeactivateModal, ZgButton },
  data() {
    return {
      showContent: false,
      showDeactivateModal: false
    };
  },
  computed: {
    arrowClass() {
      return {
        up: this.showContent,
        down: !this.showContent
      };
    }
  },
  methods: {
    toggleContent() {
      this.showContent = !this.showContent;
    },
    handleAffiliatePlanOptionDeactivate(){
      this.showDeactivateModal = false;
      // Refresh the cache of affiliate plan options now that it's deactivated
      resource('affiliatePlanOptions').refresh();
    }
  }
};
</script>

<style scoped>
  .plan-name {
    display: flex;
  }
  .arrow-box {
    margin: 0 8px;
  }
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 1px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
</style>
