<template>
    <div>
      <vue-headful title="Add Amendments & Annual Reports Field" />
      <div class="configs">
        <div>
          <div class="col-auto mb-2">
            <div class="row mb-4">
              <h1 v-if="isAllowedToEditAnnualReportConf">Add Amendments & Annual Reports Field</h1>
              <h1 v-else>It seems like you're not allowed to add an Amendments & Annual Reports Field</h1>
            </div>
            <div class="col-md-12">
              <form v-if="isAllowedToEditAnnualReportConf">
                <h2 class="header">New Amendments Info</h2>
                <div class="box">
                  <div class="row">
                    <div class="col-md-4">
                      <dt>State</dt>
                      <dd>
                        <select class="form-control" v-model="objectToUpdate.jurisdiction">
                          <option value="undefined" disabled selected></option>
                          <option v-for="state in USstates" v-bind:key="state.id">
                            {{state}}
                          </option>
                        </select>
                      </dd>
                    </div>
                    <div class="col-md-4">
                      <dt>Entity Type</dt>
                      <dd>
                        <select class="form-control" v-model="objectToUpdate.entity_type">
                          <option value="undefined" disabled selected></option>
                          <option value="corp">Corp</option>
                          <option value="llc">LLC</option>
                        </select>
                      </dd>
                    </div>
                    <div class="col-md-4">
                      <dt>Jurisdiction</dt>
                      <dd>
                        <select class="form-control" v-model="objectToUpdate.domestic_flag">
                          <option value="undefined" disabled selected></option>
                          <option value="domestic">Domestic</option>
                          <option value="foreign">Foreign</option>
                        </select>
                      </dd>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <dt>Document</dt>
                      <dd>
                        <select class="form-control" v-model="objectToUpdate.service_type">
                          <option value="undefined" disabled selected></option>
                          <option value="amendment">Amendment</option>
                          <option value="annual_report">Annual Report</option>
                        </select>
                      </dd>
                    </div>
                    <div class="col-md-4">
                      <dt>Field</dt>
                      <dd>
                        <input v-model="objectToUpdate.field_name" class="form-control" placeholder="new_field" required/>
                      </dd>
                    </div>
                    <div class="col-md-4">
                      <dt>New Value</dt>
                      <dd>
                        <select class="form-control" v-model="objectToUpdate.editable_flag">
                          <option value="undefined" disabled selected></option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </dd>
                    </div>
                  </div>
                </div>
                <div class="row float-right">
                  <button class="btn btn-primary" @click="updateObject" :disabled="!validForm">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import { yesNoMixin, editAnnualReportConfMixin } from '@/components/mixins';
  
  export default {
    name: 'AddAmendmentReportField',
    mixins: [yesNoMixin, editAnnualReportConfMixin],
    data() {
      return {
        USstates: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
          'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
          'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
          'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
          'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
        DocumentFieldsCorp: ['addresses', 'authorized_signatory', 'business_entity_name', 'business_purpose',
          'directors', 'ein', 'officers', 'stock_info'],
        DocumentFieldsLLC: ['addresses', 'authorized_signatory', 'business_entity_name', 'business_purpose',
          'ein', 'managers', 'members', 'structure'],
        objectToUpdate: {
          jurisdiction: null,
          entity_type: null,
          domestic_flag: null,
          service_type: null,
          field_name: null,
          editable_flag: null
        }
      };
    },
    computed: {
      ...mapState({
        serviceFees: (state) => state.products.serviceFees,
        reportFieldConfig: (state) => state.products.reportFieldConfig,
        products: (state) => state.orderCreate.allProducts
      }),
      validForm() {
        return !Object.values(this.objectToUpdate).some((el) => el === null);
      },
      DocumentFieldsByEntity() {
        switch (this.objectToUpdate.entity_type) {
          case 'corp':
            return this.DocumentFieldsCorp;
          case 'llc':
            return this.DocumentFieldsLLC;
          default:
            return ['Select an Entity Type'];
        }
      }
    },
    methods: {
      updateObject(e) {
        e.preventDefault();
        const updatedObject = this.preparePayloadForRequest(this.objectToUpdate);
        this.addReportField(updatedObject);
      },
      preparePayloadForRequest(obj) {
        obj.domestic_flag = obj.domestic_flag === 'domestic';
        obj.editable_flag = obj.editable_flag === 'yes';
        return obj;
      },
      addReportField(obj) {
        this.$store.dispatch('products/addReportField', obj);
      }
    }
  };
  </script>
  
  <style lang="scss">
    h1 {
      padding: 10px 0 0 10px;
      font-size: 20pt;
      display: inline;
    }
    .configs{
      margin-top: 25px;
    }
    .incomplete {
      background-color: #dc3545 !important
    }
    .partial {
      background-color: #ffc107 !important
    }
  </style>
  