<template>
  <div>
    <vue-headful title="Affiliate Pricing" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Affiliate Pricing</h1>
          <input v-if="hasPermission('write:pricing')" type="button" class="btn-info btn-sm" @click="affiliatePricingAddModal = true" value="Create Affiliate Pricing" />
          <AffiliatePricingAddModal v-if="affiliatePricingAddModal" @close="affiliatePricingAddModal = false" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Service Name" v-model="filteredService"/>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredAffiliate">
              <option :value="undefined" disabled selected>Affiliate</option>
              <option :value="undefined">All</option>
              <option v-for="affiliate in affiliates" :key="affiliate.uuid" :value="affiliate.name" >{{ affiliate.name }}</option> -->
            </select>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredInterval">
              <option :value="undefined" disabled selected>Interval</option>
              <option :value="undefined">All</option>
              <option value="onetime">One-time</option>
              <option value="monthly">Monthly</option>
              <option value="annual">Annual</option>
            </select>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredPricing" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="related_affiliate.name">Affiliate</sort-link></th>
            <th><sort-link name="related_service_pricing.service">Service Name</sort-link></th>
            <th><sort-link name="related_service_pricing.service_pricing_uuid">Pricing UUID</sort-link></th>
            <th><sort-link name="related_service_pricing.interval">Interval</sort-link></th>
            <th><sort-link name="related_service_pricing.amount">Amount</sort-link></th>
            <th><sort-link name="related_service_pricing.defer_until_formation_complete">Is Deferred?</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <AffiliatePricingListItem v-for="affiliatePricing in sort.values" :key="affiliatePricing.uuid" :affiliatePricing="affiliatePricing" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="affiliatePricing.page" :totalPages="affiliatePricing.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import {mapActions, mapState} from 'vuex';
import AffiliatePricingListItem from './affiliate-pricing-list-item';
import Pagination from '../utils/pagination';
import { hasPermissionMixin } from '@/components/mixins';
import AffiliatePricingAddModal from './affiliate-pricing-create-modal';

export default {
  name: 'AffiliatePricingList',
  components: {
    AffiliatePricingListItem,
    Pagination,
    AffiliatePricingAddModal
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
      filteredService: undefined,
      filteredInterval: undefined,
      filteredAffiliate: undefined,
      affiliatePricingAddModal: false
    };
  },
  computed: {
    ...mapState({
      affiliates: (state) => state.products.affiliates
    }),
    affiliatePricing: createInstance('affiliatePricing', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.filteredAffiliate) {
          params.related_affiliate_name = this.filteredAffiliate;
        }
        if (this.filteredService) {
          params.related_service_pricing_service = this.filteredService;
        }
        if (this.filteredInterval) {
          params.related_service_pricing_interval = this.filteredInterval;
        }
        return params;
      }
    }),
    filteredPricing() {
      return this.affiliatePricing.items;
    }
  },
  methods: {
    ...mapActions({
      fetchAffiliatesV2: 'products/fetchAffiliatesV2'
    }),
    clearFilters() {
      this.data = {
        filteredService: undefined,
        filteredInterval: undefined,
        filteredAffiliate: undefined
      };
    }
  },
  mounted() {
    this.fetchAffiliatesV2({deleted: false});
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
