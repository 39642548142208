<template>
   <div class="modal">
     <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content min-width-fit-content">
          <div class="modal-header">
            <h5 class="modal-title">Create new Service Pricing</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="clearFields(); $emit('close')"></button>
          </div>
          <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validSelectValue(this.servicePricing.service)}">Service</label>
                  <v-select
                    v-model="servicePricing.service"
                    :options="serviceProducts"
                    label='service'
                    :reduce="option=>option.service"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md">
                  <label>Coupon</label>
                  <v-select
                    v-model="servicePricing.coupon"
                    :options="coupons"
                    label='external_coupon_id'
                    :reduce="coupon=>coupon.coupon_uuid"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md">
                  <label>Price Group</label>
                  <v-select
                    v-model="servicePricing.price_group"
                    :options="priceGroups"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validSelectValue(this.servicePricing.interval)}">Interval</label>
                  <v-select
                    v-model="servicePricing.interval"
                    :options="intervals"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md">
                  <label v-bind:class="{'text-danger': !validAmountValue(this.servicePricing.amount)}" for="amount">Amount</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input v-model="servicePricing.amount" class="form-control" placeholder="0" name="Amount" id="amount" required/>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md">
                  <label for="defer">Is deferred?</label>
                  <input type="checkbox" v-model="servicePricing.defer" id="defer"/>
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button class="btn btn-primary" :disabled="!isFormValid" @click="handleCreateServicePricing" >Create</button>
          </div>
       </div>
    </div>
  </div>
</template>

<script>
import { resource } from 'vuex-pagination';
import { mapState } from 'vuex';

export default {
  name: 'ServicePricingCreateModal',
  computed: {
    ...mapState({
      serviceProducts: (state) => state.products.products,
      coupons: (state) => state.products.coupons
    }),
    isFormValid() {
      return this.validSelectValue(this.servicePricing.service)
             && this.validAmountValue(this.servicePricing.amount)
             && this.validSelectValue(this.servicePricing.interval);
    },
    priceGroups() {
      return ['partner', 'rvshare', 'truic', 'zenbusiness'];
    },
    intervals() {
      return ['onetime', 'monthly', 'annual'];
    }
  },
  data() {
    return {
      servicePricing: {
        service: null,
        coupon: null,
        price_group: null,
        interval: null,
        amount: null,
        defer: false
      }
    };
  },
  methods: {
    beforeCreate() {
      this.clearFields();
    },
    clearFields() {
      this.servicePricing.service = null;
      this.servicePricing.coupon = null;
      this.servicePricing.price_group = null;
      this.servicePricing.interval = null;
      this.servicePricing.amount = null;
      this.servicePricing.defer = false;
    },
    handleCreateServicePricing() {
      const newServicePricing = {
        service: this.servicePricing.service,
        coupon: this.servicePricing.coupon,
        price_group: this.servicePricing.price_group,
        interval: this.servicePricing.interval,
        amount: this.servicePricing.amount,
        defer_until_formation_complete: this.servicePricing.defer,
        trial_period_days: null
      };
      this.$store.dispatch('products/createServicePricing', newServicePricing)
        .then(() => {
          this.clearFields();
          resource('servicePricing').refresh();
          this.$emit('close');
        });
    },
    validAmountValue(value) {
      if (!value || value === '' || isNaN(value) || Number(value) < 0) {
        return false;
      }
      return true;
    },
    validSelectValue(value) {
      if (!value || value === '' || value.indexOf(' ') !== -1) {
        return false;
      }
      return true;
    }
  }
};
</script>
