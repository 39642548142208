<template>
  <div>
    <vue-headful title="Service Fulfillment" />
    <div class="top25 mb-5">
      <div class="row my-2">
        <div class="col-auto">
          <h3 class="mb-5">Services Fulfillment</h3>
        </div>
      </div>
      <div class="row my-3" v-if="hasPermission('access:developers')">
        <div class="col">
          <h2>Agency Login Account Creation</h2>
          <input type="text" v-model="username"/>
          <ZgButton class="nav-link btn text-info btn-link" @click="createAccount" type="button">
            <template v-slot:title><i class="fa fa-plus-circle"></i>&nbsp;</template>
          </ZgButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ZgButton from '../common/Generics/ZgButton.vue'
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'ServicesFulfillment',
  components: {
    ZgButton
  },
  mixins: [hasPermissionMixin],
  data(){
    return {
      username: ''
    }
  },
  methods: {
    ...mapActions({
      fetchJurisdictionStates: 'products/fetchJurisdictionStates',
      createAgencyLoginAccountList: 'fulfillment/createAgencyLoginAccountList'
    }),
    createAccount() {
      if(this.username) {
        this.createAgencyLoginAccountList({username: this.username})
      }
    }
  },
};
</script>

<style scoped lang="scss">
  main {
    display: flex;
    flex-direction: column;
    header {
      font-size: rem(20);
      font-weight: bold;
      padding: 1em 0 1em 0;
    }
  }
</style>