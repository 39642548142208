<template>
  <div class="center">
    <div class="pagination">
      <button :disabled="page <= 1" @click="$emit('update:page', page-1)">Back</button>
      <p>Page {{ page }} / {{ totalPages }}</p>
      <button :disabled="page > totalPages - 1" @click="$emit('update:page', page+1)">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['page', 'totalPages']
};
</script>

<style lang="scss">
  .center {
    text-align: center;
  }
  .pagination {
    display: inline-block;
    align-items: center;
    gap: 1em;
  }
  .pagination button,
  .pagination p {
    color: black;
    float: left;
    padding: 0.5em;
    text-decoration: none;
    transition: background-color .3s;
  }
  .pagination button {
    border: 1px solid #ddd;
  }
</style>
