<template>
  <div>
    <vue-headful title="Service Products" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Service Products</h1>
          <button v-if="hasPermission('write:pricing')" type="button" class="btn-info btn-sm" @click="openCreateModal">
            Create Service Product
          </button>
        </div>
        <ServiceProductCreateModal v-if="createModalVisible"
                                   :planCreationOnly="false"
                                   @close="closeCreateModal"
                                   @productCreated="closeCreateModal" />
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Service Name" v-model="filteredService"/>
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Category" v-model="filteredCategory"/>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredRecurring">
              <option :value="undefined" disabled selected>Recurring</option>
              <option :value="undefined">All</option>
              <option :value="true">True</option>
              <option :value="false">False</option>
            </select>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredVanity">
              <option :value="undefined" disabled selected>Is Vanity?</option>
              <option :value="undefined">All</option>
              <option :value="true">True</option>
              <option :value="false">False</option>
            </select>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredProducts" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="display_name">Service Name</sort-link></th>
            <th><sort-link name="service">Service ID</sort-link></th>
            <th><sort-link name="category">Category</sort-link></th>
            <th><sort-link name="recurring">Recurring</sort-link></th>
            <th><sort-link name="is_vanity">Is Vanity?</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <ServiceProductsListItem v-for="product in sort.values" :key="product.service_product_uuid" :product="product" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="products.page" :totalPages="products.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import Pagination from '../utils/pagination';
import ServiceProductsListItem from './service-products-list-item';
import ServiceProductCreateModal from './service-products-create-modal.vue';
import { hasPermissionMixin } from '../mixins.js';

export default {
  name: 'ServiceProductsList',
  components: {
    ServiceProductsListItem,
    ServiceProductCreateModal,
    Pagination
  },
  mixins: [hasPermissionMixin],
  data() {
    return {
      filteredService: undefined,
      filteredCategory: undefined,
      filteredRecurring: undefined,
      filteredVanity: undefined,
      createModalVisible: false
    };
  },
  computed: {
    products: createInstance('products', {
      page: 1,
      pageSize: 20,
      args() {
        const params = { include_vanity: true };
        if (this.filteredService) {
          params.display_name = this.filteredService;
        }
        if (this.filteredCategory) {
          params.category = this.filteredCategory;
        }
        if (this.filteredRecurring) {
          params.recurring = this.filteredRecurring;
        }
        if (this.filteredVanity) {
          params.is_vanity = this.filteredVanity;
        }
        return params;
      }
    }),
    filteredProducts() {
      return this.products.items;
    }
  },
  methods: {
    clearFilters() {
      this.data = {
        filteredService: undefined,
        filteredCategory: undefined,
        filteredRecurring: undefined,
        filteredVanity: undefined
      };
    },
    openCreateModal() {
      this.createModalVisible = true;
    },
    closeCreateModal() {
      this.createModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
