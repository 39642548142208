<template>
  <tr>
    <td>{{ product.display_name }}</td>
    <td>{{ product.service }}</td>
    <td>{{ product.category }}</td>
    <td>{{ product.recurring }}</td>
    <td>{{ product.is_vanity }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ServiceProductsListItem',
  props: ['product']
};
</script>
