<template>
  <div>
    <vue-headful title="Amendments & Annual Reports Configuration" />
    <div class="configs">
      <div>
        <div class="col-auto mb-2">
          <div class="row mb-4">
            <h1 v-if="isAllowedToEditAnnualReportConf">Edit Amendments & Annual Reports Configuration</h1>
            <h1 v-else>It seems like you're not allowed to Edit Amendments & Annual Reports Configuration</h1>
          </div>
          <div class="col-md-12">
            <form v-if="isAllowedToEditAnnualReportConf">
              <h2 class="header">New Amendments Info</h2>
              <div class="box">
                <div class="row">
                  <div class="col-md-4">
                    <dt>State</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.jurisdiction" data-testid="jurisdiction">
                        <option value="undefined" disabled selected></option>
                        <option v-for="state in USstates" v-bind:key="state.id">
                          {{state}}
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div class="col-md-4">
                    <dt>Entity Type</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.entity_type" data-testid="entity-type">
                        <option value="undefined" disabled selected></option>
                        <option value="corp">Corp</option>
                        <option value="llc">LLC</option>
                      </select>
                    </dd>
                  </div>
                  <div class="col-md-4">
                    <dt>Jurisdiction</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.domestic_flag" data-testid="domestic-flag">
                        <option value="undefined" disabled selected></option>
                        <option value="domestic">Domestic</option>
                        <option value="foreign">Foreign</option>
                      </select>
                    </dd>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <dt>Document</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.service_type" data-testid="service-type">
                        <option value="undefined" disabled selected></option>
                        <option value="amendment">Amendment</option>
                        <option value="annual_report">Annual Report</option>
                      </select>
                    </dd>
                  </div>
                  <div class="col-md-4">
                    <dt>Field</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.field_name" data-testid="field-name">
                        <option value="undefined" disabled selected></option>
                        <option v-for="field in DocumentFieldsByEntity" v-bind:key="field.id">
                          {{field}}
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div class="col-md-4">
                    <dt>New Value</dt>
                    <dd>
                      <select class="form-control" v-model="objectToUpdate.editable_flag" data-testid="editable-flag">
                        <option value="undefined" disabled selected></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </dd>
                  </div>
                </div>
              </div>
              <div class="row float-right">
                <button class="btn btn-primary" @click="updateObject" :disabled="!validForm" data-testid="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { yesNoMixin, editAnnualReportConfMixin } from '@/components/mixins';

export default {
  name: 'EditAmendmentReportFieldConfig',
  mixins: [yesNoMixin, editAnnualReportConfMixin],
  data() {
    return {
      USstates: ['AK', 'AL', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
      objectToUpdate: {
        jurisdiction: null,
        entity_type: null,
        domestic_flag: null,
        service_type: null,
        field_name: null,
        editable_flag: null
      }
    };
  },
  computed: {
    ...mapGetters({
      reportFieldConfig: 'products/getReportFieldConfig',
    }),
    ...mapState({
      serviceFees: (state) => state.products.serviceFees,
      products: (state) => state.orderCreate.allProducts
    }),
    validForm() {
      return !Object.values(this.objectToUpdate).some((el) => el === null);
    },
    DocumentFieldsByEntity() {
      const {
        jurisdiction,
        entity_type: entityType,
        domestic_flag: domesticFlag,
        service_type: serviceType
      } = this.objectToUpdate;

      if (Object.values({ jurisdiction, entityType, domesticFlag, serviceType }).some((el) => el === null)) {
        return ['Select a State, Entity Type, Jurisdiction, and Document'];
      }
      return Object.keys(this.reportFieldConfig[jurisdiction][entityType][domesticFlag][serviceType]).sort()
    }
  },
  methods: {
    ...mapActions({
      fetchReportFieldConfig: 'products/fetchReportFieldConfig'
    }),
    updateObject(e) {
      e.preventDefault();
      const updatedObject = this.preparePayloadForRequest(this.objectToUpdate);
      this.updateReportFieldConfig(updatedObject);
    },
    preparePayloadForRequest(obj) {
      let result = { ...obj };
      result.domestic_flag = obj.domestic_flag === 'domestic';
      result.editable_flag = obj.editable_flag === 'yes';
      result.jurisdiction = `US_${obj.jurisdiction}`;
      return result;
    },
    updateReportFieldConfig(obj) {
      this.$store.dispatch('products/updateReportFieldConfig', obj);
      this.$router.go(-1);
    }
  },
  created() {
    this.fetchReportFieldConfig();
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .configs{
    margin-top: 25px;
  }
  .incomplete {
    background-color: #dc3545 !important
  }
  .partial {
    background-color: #ffc107 !important
  }
</style>
