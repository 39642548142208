<template>
  <div>
    <vue-headful title="Service C-Corp" />
    <div class="top25">
      <div class="row">
        <div class="col-auto">
          <h3 class="mb-5">Services C-Corp</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ServicesCCorpCalculator />
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-12">
          <main>
            <header>Fields and Fees for C-Corp Stock</header>
          
          <br>
          <ZgButton class="nav-link btn text-info btn-link" @click="openManageTerms" type="button">
            <template v-slot:title><i class="fa fa-plus-circle"></i>&nbsp;Manage Terms</template>
          </ZgButton>
          <ServicesCCorpManageTerms v-if="isManageTermsModalOpen"  @close="isManageTermsModalOpen = false" />
          </main>
        </div>
        <div class="col-md-12">
          <ServicesCCorpListItem v-for="jurisdiction in getJurisdictionStates" :key="jurisdiction.jurisdiction_definition_uuid" class="mb-4" :jurisdiction="jurisdiction"></ServicesCCorpListItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ServicesCCorpListItem from './services-c-corp-list-item';
import ServicesCCorpManageTerms from './services-c-corp-manage-terms';
import ServicesCCorpCalculator from './services-c-corp-calculator';
import ZgButton from '../common/Generics/ZgButton.vue'
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'ServicesCCorpList',
  components: {
    ServicesCCorpListItem,
    ServicesCCorpManageTerms,
    ServicesCCorpCalculator,
    ZgButton
  },
  mixins: [hasPermissionMixin],
  data(){
    return {
      isManageTermsModalOpen: false,
      username: null
    }
  },
  methods: {
  ...mapActions({
    fetchJurisdictionStates: 'products/fetchJurisdictionStates',
    createAgencyLoginAccountList: 'fulfillment/createAgencyLoginAccountList'
  }),
    openManageTerms() {
      this.isManageTermsModalOpen = true
    },
  },
  computed: {
    getJurisdictionStates() {
      return this.$store.state.products.jurisdictionStates
    }
  },
  mounted() {
    this.fetchJurisdictionStates()
  }
};
</script>

<style scoped lang="scss">
  main {
    display: flex;
    flex-direction: column;
    header {
      font-size: rem(20);
      font-weight: bold;
      padding: 1em 0 1em 0;
    }
  }
</style>