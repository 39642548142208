<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Plan Option</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-row">
            <label>Search by plan name
              <input v-model="searchQuery" />
            </label>
          </div>
          <div class="overflow-scroll">
            <div class="form-row"
                 v-for="planOption in filteredPlanOptions"
                 :key="planOption.uuid">
              <div class="form-group col-md">
                <div class="btn btn-info btn-sm action"
                     @click="$emit('planOptionSelected', planOption)">Select</div>
              </div>
              <div class="form-group col-md">
                <table>
                  <tbody>
                    <PlanOptionListItem :planOption="planOption" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="planOptions.length === 0">Loading plan options...</div>
          <div v-if="planOptions.length > 0 && filteredPlanOptions.length === 0">No plan options found.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PlanOptionListItem from './plan-option-list-item';

export default {
  name: 'PlanOptionSelectModal',
  components: {
    PlanOptionListItem
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    ...mapState('products', ['planOptions']),
    filteredPlanOptions() {
      return this.planOptions.filter((po) => po.related_plan_pricing.service.startsWith(this.searchQuery));
    }
  },
  mounted() {
    this.$store.dispatch('products/fetchPlanOptionsV2');
  }
};
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
  }
  .overflow-scroll {
    max-height: 80vh;
    overflow-y: auto;
  }
</style>
