import { render, staticRenderFns } from "./services-c-corp-list.vue?vue&type=template&id=6d86a5a8&scoped=true"
import script from "./services-c-corp-list.vue?vue&type=script&lang=js"
export * from "./services-c-corp-list.vue?vue&type=script&lang=js"
import style0 from "./services-c-corp-list.vue?vue&type=style&index=0&id=6d86a5a8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d86a5a8",
  null
  
)

export default component.exports