<template>
  <th @click="toggleState">
    <slot></slot>
    <template v-if="iconOrder">
      &nbsp;<i class="fa" :class="iconOrder"></i>
    </template>
  </th>
</template>
<script>
export default {
  name: 'ZgOrderrTH',
  props: ['order'],
  computed: {
    iconOrder() {
      if (this.order == undefined) return 'fa-th-list';
      if (this.order == 'asc') return 'fa-arrow-up';
      if (this.order == 'desc') return 'fa-arrow-down';
      return null;
    }
  },
  methods: {
    toggleState() {
      switch (this.order) {
        case 'asc':
          this.$emit('update:order', 'desc');
          break;
        case 'desc':
          this.$emit('update:order', undefined);
          break;
        default:
          this.$emit('update:order', 'asc');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  th {
    cursor: pointer;
  }
</style>
