<template>
  <div>
    <vue-headful title="Service Product Plans" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Service Product Plans</h1>
          <div v-if="this.hasPermission('write:pricing')"
               class="btn btn-sm btn-primary action"
               @click="showCreateModal = true">Create</div>
          <ServiceProductPlansCreateModal v-if="showCreateModal"
                                          @serviceProductPlansCreated="handleServiceProductPlansCreated"
                                          @close="showCreateModal = false" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Plan Name ID" v-model="filteredPlanNameId"/>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredPlanType">
              <option :value="undefined" disabled selected>Plan Type</option>
              <option :value="undefined">All</option>
              <option value="starter_plan">Starter</option>
              <option value="pro_plan">Pro</option>
              <option value="premium_plan">Premium</option>
            </select>
            <input class="form-control-sm mx-2 mb-2" type="number" placeholder="Version" v-model="filteredPlanVersion"/>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredProductPlan" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="plan_id">Plan Name ID</sort-link></th>
            <th><sort-link name="display_name">Plan Name</sort-link></th>
            <th><sort-link name="display_name">Products</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <ServiceProductPlanListItem v-for="serviceProductPlan in sort.values" :key="serviceProductPlan.service_product_plan_uuid" :serviceProductPlan="serviceProductPlan" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="serviceProductPlans.page" :totalPages="serviceProductPlans.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance, resource } from 'vuex-pagination';
import Pagination from '../utils/pagination';
import ServiceProductPlanListItem from './service-product-plans-list-item';
import ServiceProductPlansCreateModal from './service-product-plans-create-modal';
import { hasPermissionMixin } from '../mixins';

export default {
  name: 'ServiceProductPlansList',
  components: {
    ServiceProductPlansCreateModal,
    Pagination,
    ServiceProductPlanListItem
  },
  mixins: [hasPermissionMixin],
  data() {
    return {
      filteredPlanNameId: undefined,
      filteredPlanType: undefined,
      filteredPlanVersion: undefined,
      showCreateModal: false
    };
  },
  computed: {
    serviceProductPlans: createInstance('serviceProductPlans', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.filteredPlanNameId) {
          params.plan_product_display_name = this.filteredPlanNameId;
        }
        if (this.filteredPlanType) {
          params.plan_type = this.filteredPlanType;
        }
        if (this.filteredPlanVersion) {
          params.plan_version = `v${this.filteredPlanVersion}`;
        }
        return params;
      }
    }),
    filteredProductPlan() {
      return this.serviceProductPlans.items;
    }
  },
  methods: {
    clearFilters() {
      this.filteredPlanNameId = undefined;
      this.filteredPlanType = undefined;
      this.filteredPlanVersion = undefined;
    },
    handleServiceProductPlansCreated() {
      this.showCreateModal = false;
      resource('serviceProductPlans').refresh();
    }
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
