<template>
  <tr>
    <td v-if="showAffiliateName">{{ affiliatePricing.related_affiliate.name }}</td>
    <td>{{ affiliatePricing.related_service_pricing.service }}</td>
    <td>{{ affiliatePricing.related_service_pricing.service_pricing_uuid }}</td>
    <td>{{ affiliatePricing.related_service_pricing.interval }}</td>
    <td>{{ this.money(affiliatePricing.related_service_pricing.amount) }}</td>
    <td>{{ affiliatePricing.related_service_pricing.defer_until_formation_complete }}</td>
  </tr>
</template>

<script>
import { moneyMixin } from '@/components/mixins';

export default {
  name: 'AffiliatePricingListItem',
  props: {
    affiliatePricing: {},
    showAffiliateName: {
      default: true
    }
  },
  mixins: [moneyMixin]
};
</script>
