<template>
  <tr>
    <td v-if="planItem.display_name">{{ planItem.display_name }}</td>
    <td v-if="planItem.service">{{ planItem.service }}</td>
    <td v-if="planItem.interval">{{ planItem.interval }}</td>
    <td v-if="planItem.amount">{{ this.money(planItem.amount) }}</td><td v-else></td>
    <td v-if="planItem.coupon">{{ planItem.coupon }}</td><td v-else></td>
    <td v-if="planItem.defer_until_formation_complete == false">No</td> <td v-else>Yes</td>

  </tr>
</template>

<script>
import { moneyMixin} from '@/components/mixins';
export default {
name: 'PlanListItem',
props: {
planItem: {
  required: true
},
readOnly: {
  default: false
}
},
mixins: [moneyMixin],
data() {
return {
  editMode: false
};
}
};
</script>

<style scoped>
.btn {
padding-bottom: 0px;
padding-top: 0px;
}
</style>
