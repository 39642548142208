<template>
  <div>
    <vue-headful title="Plans" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Plans</h1>
          <input v-if="hasPermission('write:pricing')" type="button" class="btn-info btn-sm" @click="planOptionCreateModal = true" value="Create Plan" />
          <PlanOptionCreateModal  v-if="planOptionCreateModal" @close="planOptionCreateModal = false" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Plan" v-model="filteredPlan"/>
            <select class="form-control-sm mx-2 mb-2" v-model="filteredPlanType">
              <option :value="undefined" disabled selected>Plan Type</option>
              <option :value="undefined">All</option>
              <option value="starter_plan">Starter</option>
              <option value="pro_plan">Pro</option>
              <option value="premium_plan">Premium</option>
            </select>
            <input class="form-control-sm mx-2 mb-2" type="number" placeholder="Version" v-model="filteredVersion"/>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredPlans" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="related_plan_pricing.service">Plan</sort-link></th>
            <th><sort-link name="related_plan_pricing.amount">Amount</sort-link></th>
            <th><sort-link name="related_plan_pricing.coupon">Coupon</sort-link></th>
            <th class="center"><sort-link name="can_be_ordered_zg">Manual Order?</sort-link></th>
            <th class="center"><sort-link name="can_be_downgraded_zg">Downgrade Modal?</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <PlanOptionListItem v-for="planOption in sort.values" :key="planOption.uuid" :planOption="planOption" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="planOptions.page" :totalPages="planOptions.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import get from 'lodash/get';
import Vue from 'vue';
import { hasPermissionMixin } from '../mixins.js';
import Pagination from '../utils/pagination';
import PlanOptionListItem from './plan-option-list-item';
import PlanOptionCreateModal from './plan-option-create-modal';

// Enable sorting by nested attributes
Vue.prototype.$_ = {
  get
};

export default {
  name: 'PlanOptionList',
  components: {
    PlanOptionCreateModal,
    PlanOptionListItem,
    Pagination
  },
  mixins: [hasPermissionMixin],
  data() {
    return {
      filteredPlan: undefined,
      filteredVersion: undefined,
      filteredPlanType: undefined,
      planOptionCreateModal: false
    };
  },
  computed: {
    planOptions: createInstance('planOptions', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.filteredPlan) {
          params.related_plan_pricing_service = this.filteredPlan;
        }
        if (this.filteredVersion) {
          params.plan_version = `v${this.filteredVersion}`;
        }
        if (this.filteredPlanType) {
          params.plan_type = this.filteredPlanType;
        }
        return params;
      }
    }),
    filteredPlans() {
      return this.planOptions.items.filter((planOption) => {
        if (this.filteredPlan && !planOption.related_plan_pricing.service.toLowerCase().startsWith(this.filteredPlan.toLowerCase())) {
          return false;
        }
        if (this.filteredPlanType && !planOption.related_plan_pricing.service.toLowerCase().includes(this.filteredPlanType.toLowerCase())) {
          return false;
        }
        return true;
      });
    }
  },
  methods: {
    clearFilters() {
      this.data = {
        filteredPlan: undefined,
        filteredPlanType: undefined
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
  .center {
    justify-content: center;
  }
</style>
