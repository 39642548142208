<template>
  <div>
    <vue-headful title="Service Pricing" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Service Pricing</h1>
        </div>
        <div class="col-auto"  v-if="showCreateServicePricing">
          <div class="btn btn-sm btn-info" @click="createServicePricingClicked"
              data-toggle="tooltip" data-placement="top" title="Add Service Pricing">Add Service Pricing</div>
        </div>
        <div class="col-auto" >
          <ServicePricingCreateModal  v-if="servicePricingCreateModal" @close="servicePricingCreateModal = false" />
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="clearFilters">
            <input class="form-control-sm mx-2 mb-2" type="text" placeholder="Service Name" v-model="service"/>
            <select class="form-control-sm mx-2 mb-2" v-model="interval">
              <option :value="undefined" disabled selected>Interval</option>
              <option :value="undefined">All</option>
              <option value="onetime">One-time</option>
              <option value="monthly">Monthly</option>
              <option value="annual">Annual</option>
            </select>
            <select class="form-control-sm mx-2 mb-2" v-model="deferred">
              <option :value="undefined" disabled selected>Is Deferred?</option>
              <option :value="undefined">All</option>
              <option :value="true">True</option>
              <option :value="false">False</option>
            </select>
            <input class="btn-info btn-sm mx-2 mb-2" type="submit" value="Clear Filters"/>
          </form>
        </div>
      </div>
      <sorted-table :values="filteredPricing" class="table table-sm">
        <thead>
          <tr>
            <th><sort-link name="display_name">Service Name</sort-link></th>
            <th><sort-link name="service_pricing_uuid">service_pricing_uuid</sort-link></th>
            <th><sort-link name="service">Service ID</sort-link></th>
            <th><sort-link name="interval">Interval</sort-link></th>
            <th><sort-link name="amount">Amount</sort-link></th>
            <th><sort-link name="coupon_name">Coupon</sort-link></th>
            <th><sort-link name="defer_until_formation_complete">Is Deferred?</sort-link></th>
          </tr>
        </thead>
        <tbody slot="body" slot-scope="sort">
          <ServicePricingListItem v-for="servicePricing in sort.values" :key="servicePricing.service_pricing_uuid" :servicePricing="servicePricing" />
        </tbody>
      </sorted-table>
      <Pagination :page.sync="servicePricing.page" :totalPages="servicePricing.totalPages" />
    </div>
  </div>
</template>

<script>
import { createInstance } from 'vuex-pagination';
import Pagination from '../utils/pagination';
import ServicePricingCreateModal from './service-pricing-create-modal';
import ServicePricingListItem from './service-pricing-list-item';
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'ServicePricingList',
  components: {
    Pagination,
    ServicePricingListItem,
    ServicePricingCreateModal
  },
  mixins: [
    hasPermissionMixin
  ],
  data() {
    return {
      service: undefined,
      deferred: undefined,
      interval: undefined,
      priceGroup: undefined,
      servicePricingCreateModal: false
    };
  },
  computed: {
    servicePricing: createInstance('servicePricing', {
      page: 1,
      pageSize: 20,
      args() {
        const params = {};
        if (this.service) {
          params.service_display_name = this.service;
        }
        if (this.interval) {
          params.interval = this.interval;
        }
        if (this.deferred) {
          params.defer_until_formation_complete = this.deferred;
        }
        return params;
      }
    }),
    filteredPricing() {
      return this.servicePricing.items;
    },
    showCreateServicePricing() {
      return this.hasPermission('write:pricing');
    }
  },
  methods: {
    clearFilters() {
      this.data = {
        service: undefined,
        deferred: undefined,
        interval: undefined,
        priceGroup: undefined
      };
    },
    createServicePricingClicked() {
      this.servicePricingCreateModal = true;
    }
  },
  mounted() {
    const serviceProductData = {
      fields: ['service', 'display_name'],
      getAll: true
    };
    const couponData = {
      fields: ['external_coupon_id', 'coupon_uuid'],
      getAll: true
    };
    this.$store.dispatch('products/fetchServiceProductsV2', serviceProductData);
    this.$store.dispatch('products/fetchCouponsV2', couponData);
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
</style>
