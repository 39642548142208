<template>
    <tr>
    <td v-if="planItem.product_pricing">{{ planItem.product_pricing.display_name }}</td> <td v-else>{{ planItem.addon_pricing.display_name }}</td>
    <td v-if="planItem.product_pricing">{{ planItem.product_pricing.service }}</td><td v-else>{{ planItem.addon_pricing.service }}</td>
    <td v-if="planItem.product_pricing">{{ planItem.product_pricing.service_pricing_uuid }}</td> <td v-else>{{ planItem.addon_pricing.service_pricing_uuid}}</td>
    <td v-if="planItem.product_pricing">{{ planItem.product_pricing.interval }}</td><td v-else>{{ planItem.addon_pricing.interval }}</td>
    <td v-if="planItem.product_pricing"><div v-if="planItem.product_pricing">{{ this.money(planItem.product_pricing.amount) }}</div></td><td v-else><div v-if="planItem.addon_pricing">{{ this.money(planItem.addon_pricing.amount) }}</div></td>
    <td v-if="planItem.product_pricing"><div v-if="planItem.attributed_price">{{ this.money(planItem.attributed_price) }}</div></td>
    <td v-if="planItem.product_pricing"><div v-if="planItem.product_pricing.coupon">{{ this.money(planItem.product_pricing.coupon.amount) }}</div></td> <td v-else ><div v-if="planItem.addon_pricing.coupon">{{ this.money(planItem.addon_pricing.coupon.amount) }}</div></td>
    <td v-if="planItem.product_pricing">{{ planItem.product_pricing.defer_until_formation_complete }}</td> <td v-else>{{ planItem.addon_pricing.defer_until_formation_complete }}</td>
    </tr>
</template>

<script>
import { moneyMixin} from '@/components/mixins';
export default {
name: 'AffiliatePlanListItem',
props: {
  planItem: {
    required: true
  },
  readOnly: {
    default: false
  }
},
mixins: [moneyMixin],
data() {
  return {
    editMode: false
  };
}
};
</script>

<style scoped>
.btn {
  padding-bottom: 0px;
  padding-top: 0px;
}
</style>
