<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="planCreationOnly" class="modal-title">First, create a plan</h5>
          <h5 v-else class="modal-title">Create service product</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label :class="{'text-danger': !serviceIsValid[0]}" for="service-id" data-toggle="tooltip" :title="tooltips.service">Service ID (?)</label>
                <input v-model="product.service" class="form-control" placeholder="new_product / new_plan_v10" name="Service ID" id="service-id" required>
                <small v-if="serviceIsValid[1]" class="form-text text-danger">{{ serviceIsValid[1] }}</small>
              </div>
              <div class="form-group col-md-6">
                <label :class="{'text-danger': !validValue(product.display_name)}" for="display-name">Display Name</label>
                <input v-model="product.display_name" class="form-control" placeholder="New Product" type="text" name="Display Name" id="display-name" required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label :class="{'text-danger': !validValue(product.description) }" for="description">Description</label>
                <textarea v-model="product.description" class="form-control" id="description" rows="2" placeholder="New product description" name="Description" required />
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <input v-model="product.active_flag" class="form-check-input" type="checkbox" id="active-flag">
                  <label class="form-check-label" for="active-flag">
                    Active
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="product.is_vanity" class="form-check-input" type="checkbox" id="is-vanity" :disabled="vanityDisabled" data-toggle="tooltip" :title="tooltips.vanity">
                  <label class="form-check-label" for="is-vanity" data-toggle="tooltip" :title="tooltips.vanity">
                    Vanity (?)
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="product.recurring" class="form-check-input" type="checkbox" id="recurring">
                  <label class="form-check-label" for="recurring">
                    Recurring
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="product.requires_delivery" class="form-check-input" type="checkbox" :disabled="planCreationOnly" id="requires-delivery">
                  <label class="form-check-label" for="requires-delivery">
                    Requires Delivery
                  </label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <input v-model="product.requires_activation" class="form-check-input" type="checkbox" :disabled="planCreationOnly" id="requires-activation">
                  <label class="form-check-label" for="requires-activation">
                    Requires Activation
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="product.requires_jurisdiction" class="form-check-input" type="checkbox" id="requires-jurisdiction">
                  <label class="form-check-label" for="requires-activation">
                    Requires Jurisdiction
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group form-select">
                <label :class="{'text-danger': !validValue(product.category)}" for="category">Category</label>
                <select class="form-control" id="category" v-model="product.category" :disabled="planCreationOnly" @change="categorySelected" required>
                  <option disabled value="">Select an option</option>
                  <option v-for="c in categories" :key="c.value" :value="c.value">{{ c.text }}</option>
                </select>
              </div>
              <div class="form-group form-select col-md-4">
                <label for="reporting-category" data-toggle="tooltip" :title="tooltips.reporting">Reporting category (?)</label>
                <select class="form-control" id="reporting-category" v-model="product.product_category" @change="selectProductSubcategory" :disabled="reportingCategoryDisabled">
                  <option v-for="c in Object.keys(reportingOptions)" :key="c" :value="c">{{ c }}</option>
                </select>
              </div>
              <div class="form-group form-select col-md-4">
                <label for="reporting-subcategory" data-toggle="tooltip" :title="tooltips.reporting">Reporting subcategory (?)</label>
                <select class="form-control" id="reporting-subcategory" v-model="product.product_subcategory" :disabled="reportingSubcategoryDisabled">
                  <option v-for="s in reportingOptions[product.product_category]" :key="s" :value="s">{{ s }}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group form-select col-md-4">
                <label for="default-coupon">Default Coupon</label>
                <v-select
                  id="default-coupon"
                  v-model="product.default_coupon"
                  label="external_coupon_id"
                  :reduce="external_coupon_id => external_coupon_id.coupon_uuid"
                  :options="coupons"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="product-group-name">Group Name</label>
                <input v-model="product.product_group_name" class="form-control" placeholder="Group Name" type="text" name="Group Name" id="product-group-name">
              </div>
              <div class="form-group col-md-4">
                <label for="product-group-rank">Group Rank</label>
                <input v-model="product.product_group_rank" type="number" id="product-group-rank" name="Group Rank" class="form-control" placeholder="0">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
            :disabled="!formIsValid"
            @click="handleCreateProduct">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resource } from 'vuex-pagination';
import { mapState, mapGetters } from 'vuex';
import { inputValidationsMixin } from '../mixins.js';

export default {
  name: 'ServiceProductCreateModal',
  mixins: [inputValidationsMixin],
  // A boolean flag that indicates whether the user can create any product or only a plan product. If true, certain
  // fields are pre-populated with values and are not editable.
  props: ['planCreationOnly'],
  data() {
    return {
      tooltips: {
        service: 'Custom unique identifier',
        vanity: 'Product used for A/B testing',
        reporting: 'Used for data analysis'
      },
      product: {
        service: null,
        display_name: null,
        description: null,
        active_flag: true,
        is_vanity: false,
        recurring: false,
        requires_delivery: false,
        requires_activation: false,
        requires_jurisdiction: false,
        category: '',
        product_category: 'Unknown',
        product_subcategory: 'Unknown',
        default_coupon: null,
        product_group_name: null,
        product_group_rank: null
      },
      requiredFields: [
        'service',
        'display_name',
        'description',
        'category'
      ],
      categories: [
        { value: 'Formation', text: 'Formation' },
        { value: 'service', text: 'Service' },
        { value: 'software', text: 'Software' },
        { value: 'plan', text: 'Plan' }
      ],
      reportingOptions: {
        Unknown: ['Unknown'],
        Compliance: [
          'Annual Report',
          'Anonymity',
          'Audit Protection',
          'Business Identity Protection',
          'Business License Report',
          'Corporate Documents',
          'DBA Compliance',
          'Extra Credits',
          'Good Standing',
          'Initial Report',
          'Insurance',
          'Operating Agreement',
          'Registered Agent',
          'Worry Free Guarantee'
        ],
        Finance: [
          'Accounting',
          'Banking',
          'Banking Resolution',
          'Business Tax Preparation',
          'CPA Consultation',
          'Credit Card Offer',
          'DUNS',
          'EIN',
          'Pay It Forward',
          'S Corp',
          'Tax Filing',
          'Tax Payment Assistance',
          'Xero'
        ],
        Formation: [
          'Amendment',
          'DBA Filing',
          'Entity Name Reservation',
          'Formation',
          'Fullfilment Speed',
          'Future File Date',
          'NY Publication',
          'Other',
          'Unknown'
        ],
        Plan: ['Plan'],
        Web: [
          'Ads Credit',
          'Basic Email',
          'Domain Name Privacy',
          'Domain Name Registration',
          'Static Website'
        ]
      }
    };
  },
  methods: {
    clearFields() {
      this.product = {
        service: null,
        display_name: null,
        description: null,
        active_flag: true,
        is_vanity: false,
        recurring: false,
        requires_delivery: false,
        requires_activation: false,
        requires_jurisdiction: false,
        category: '',
        product_category: 'Unknown',
        product_subcategory: 'Unknown',
        default_coupon: null,
        product_group_name: null,
        product_group_rank: null
      };
    },
    categorySelected() {
      if (this.product.category === 'Formation') {
        this.product.product_category = 'Formation';
        this.product.product_subcategory = this.reportingOptions[this.product.product_category][0];
      } else if (this.product.category === 'plan') {
        this.product.product_category = 'Plan';
        this.product.product_subcategory = 'Plan';
        this.product.is_vanity = false;
      }
    },
    selectProductSubcategory() {
      this.product_subcategory = this.reportingOptions[this.product_category][0];
    },
    handleCreateProduct() {
      this.$store.dispatch('products/createProduct', this.product)
        .then((createdProduct) => {
          this.clearFields();
          resource('products').refresh();
          this.$emit('productCreated', createdProduct);
        });
    }
  },
  computed: {
    ...mapState('products', ['coupons']),
    ...mapGetters('products', ['serviceNameIDs']),
    serviceIsValid() {
      const fieldName = 'Service ID';
      const prefix = 'vanity_';

      const valid = this.validValue(this.product.service);
      if (!valid) return [valid, null];

      if (!/^[A-Za-z0-9_]+$/.test(this.product.service)) return [false, `${fieldName} must contain just numbers, letters and underscores`];

      if (this.product.is_vanity && !this.product.service.startsWith(prefix)) return [false, `${fieldName} must start with "${prefix}"`];

      if (this.serviceNameIDs.includes(this.product.service)) return [false, `${fieldName} already exists`];

      return [true, null];
    },
    formIsValid() {
      const requiredFieldscompleted = this.requiredFields.map((field) => !!this.product[field]);
      return [this.serviceIsValid[0], ...requiredFieldscompleted].every((v) => v);
    },
    vanityDisabled() {
      return (this.product.category === 'plan') || this.planCreationOnly;
    },
    reportingCategoryDisabled() {
      const categoryValues = ['plan', 'Formation'];
      return categoryValues.includes(this.product.category) || this.planCreationOnly;
    },
    reportingSubcategoryDisabled() {
      return (this.product.category === 'plan') || this.planCreationOnly;
    }
  },
  mounted() {
    this.$store.dispatch('products/fetchAllCoupons');
    this.$store.dispatch('products/fetchAllProducts');

    if (this.planCreationOnly) {
      this.product.category = 'plan';
      this.product.product_category = 'Plan';
      this.product.product_subcategory = 'Plan';
    }
  }
};
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
  }
</style>
