<template>
  <div class="modal">
    <ServiceProductCreateModal v-if="showCreatePlanModal"
                               :planCreationOnly="true"
                               @close="$emit('close')"
                               @productCreated="handleProductCreated" />
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <h5 class="modal-title">Create New Service Product Plan Objects</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div v-if="planProduct" class="form-row">
            <div class="form-group col-md">
              Plan: {{ planProduct.service }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md">
              <label>Choose products to include in the plan</label>
              <v-select :options="includeableProducts"
                        @input="addProduct" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md">
              Included Products:
              <!-- table of included products and their monthly and annual prices -->
              <div v-if="planProducts.length > 0" class="products-container">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>Product name</th>
                      <th>Monthly price</th>
                      <th>Annual price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in planProducts" v-bind:key="p.product">
                      <td>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Remove Product" @click="removeProduct(p.product)"></button>
                      </td>
                      <td class="pr-1">{{ p.product }}</td>
                      <td>
                        <input v-model="p.monthly_price"
                               v-bind:class="{'text-danger': !isValidPriceValue(p.monthly_price)}"
                               class="form-control" />
                      </td>
                      <td>
                        <input v-model="p.annual_price"
                               v-bind:class="{'text-danger': !isValidPriceValue(p.annual_price)}"
                               class="form-control" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                Leave price fields blank to save null values.
              </div>
              <div v-else class="d-inline-block">None selected.</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="!isFormValid"
                  @click="createServiceProductPlans">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServiceProductCreateModal from './service-products-create-modal.vue';

export default {
  name: 'ServiceProductPlansCreateModal',
  components: { ServiceProductCreateModal },
  computed: {
    ...mapState({
      products: (state) => state.products.products
    }),
    includeableProducts() {
      // Filter out products that are plans and that are not included already
      return this.products
        .filter((p) => p.category !== 'plan')
        .filter((p) => this.planProducts.findIndex((product) => product.product === p.service) === -1)
        .map((p) => p.service)
        .sort();
    },
    isFormValid() {
      const invalidPricePresent = this.planProducts.some((p) => !this.isValidPriceValue(p.monthly_price) || !this.isValidPriceValue(p.annual_price));
      return (this.planProduct !== null) && (this.planProducts.length > 0) && !invalidPricePresent;
    }
  },
  data() {
    return {
      planProduct: null,
      planProducts: [],
      showCreatePlanModal: true
    };
  },
  methods: {
    addProduct(p) {
      const productDefinition = this.products.find((product) => product.service === p);
      if (!productDefinition) {
        return;
      }
      this.planProducts.push({
        product: p,
        monthly_price: '0',
        annual_price: '0',
        uuid: productDefinition.service_product_uuid
      });
      this.planProducts.sort((p1, p2) => p1.product.localeCompare(p2.product));
    },
    removeProduct(p) {
      const index = this.planProducts.findIndex((product) => product.product === p);
      if (index !== -1) {
        this.planProducts.splice(index, 1);
      }
    },
    isValidPriceValue(val) {
      // Check for empty string, or nonnegative number
      if (val === '') {
        return true;
      }
      const parsedVal = parseInt(val, 10);
      return !isNaN(parsedVal) && parsedVal >= 0;
    },
    handleProductCreated(product) {
      this.showCreatePlanModal = false;
      this.planProduct = product;
    },
    createServiceProductPlans() {
      const newServiceProductPlans = this.planProducts.map((p) => ({
        plan_product: this.planProduct.service_product_uuid,
        product: p.uuid,
        product_price: p.monthly_price === '' ? null : parseInt(p.monthly_price, 10),
        annual_product_price: p.annual_price === '' ? null : parseInt(p.annual_price, 10)
      }));
      this.$store.dispatch('products/createServiceProductPlans', newServiceProductPlans)
        .then(() => {
          this.$emit('serviceProductPlansCreated');
        });
    }
  },
  mounted() {
    this.$store.dispatch('products/fetchAllProducts');
  }
};
</script>
